/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const thimphuProtocol = $root.thimphuProtocol = (() => {

    /**
     * Namespace thimphuProtocol.
     * @exports thimphuProtocol
     * @namespace
     */
    const thimphuProtocol = {};

    /**
     * DeviceId enum.
     * @name thimphuProtocol.DeviceId
     * @enum {number}
     * @property {number} BOARD_1=0 BOARD_1 value
     * @property {number} HIH6120_1=1 HIH6120_1 value
     * @property {number} MICROPUMP_1=2 MICROPUMP_1 value
     * @property {number} MOTOR_PUMP_1=3 MOTOR_PUMP_1 value
     * @property {number} VCSEL_1=4 VCSEL_1 value
     */
    thimphuProtocol.DeviceId = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BOARD_1"] = 0;
        values[valuesById[1] = "HIH6120_1"] = 1;
        values[valuesById[2] = "MICROPUMP_1"] = 2;
        values[valuesById[3] = "MOTOR_PUMP_1"] = 3;
        values[valuesById[4] = "VCSEL_1"] = 4;
        return values;
    })();

    /**
     * MemId enum.
     * @name thimphuProtocol.MemId
     * @enum {number}
     * @property {number} EEPROM=0 EEPROM value
     */
    thimphuProtocol.MemId = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EEPROM"] = 0;
        return values;
    })();

    thimphuProtocol.MicropumpConfig = (function() {

        /**
         * Properties of a MicropumpConfig.
         * @memberof thimphuProtocol
         * @interface IMicropumpConfig
         * @property {number|null} [clock] MicropumpConfig clock
         * @property {number|null} [amplitude] MicropumpConfig amplitude
         */

        /**
         * Constructs a new MicropumpConfig.
         * @memberof thimphuProtocol
         * @classdesc Represents a MicropumpConfig.
         * @implements IMicropumpConfig
         * @constructor
         * @param {thimphuProtocol.IMicropumpConfig=} [properties] Properties to set
         */
        function MicropumpConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MicropumpConfig clock.
         * @member {number} clock
         * @memberof thimphuProtocol.MicropumpConfig
         * @instance
         */
        MicropumpConfig.prototype.clock = 0;

        /**
         * MicropumpConfig amplitude.
         * @member {number} amplitude
         * @memberof thimphuProtocol.MicropumpConfig
         * @instance
         */
        MicropumpConfig.prototype.amplitude = 0;

        /**
         * Creates a new MicropumpConfig instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.MicropumpConfig
         * @static
         * @param {thimphuProtocol.IMicropumpConfig=} [properties] Properties to set
         * @returns {thimphuProtocol.MicropumpConfig} MicropumpConfig instance
         */
        MicropumpConfig.create = function create(properties) {
            return new MicropumpConfig(properties);
        };

        /**
         * Encodes the specified MicropumpConfig message. Does not implicitly {@link thimphuProtocol.MicropumpConfig.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.MicropumpConfig
         * @static
         * @param {thimphuProtocol.IMicropumpConfig} message MicropumpConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MicropumpConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clock != null && Object.hasOwnProperty.call(message, "clock"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.clock);
            if (message.amplitude != null && Object.hasOwnProperty.call(message, "amplitude"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.amplitude);
            return writer;
        };

        /**
         * Encodes the specified MicropumpConfig message, length delimited. Does not implicitly {@link thimphuProtocol.MicropumpConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.MicropumpConfig
         * @static
         * @param {thimphuProtocol.IMicropumpConfig} message MicropumpConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MicropumpConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MicropumpConfig message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.MicropumpConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.MicropumpConfig} MicropumpConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MicropumpConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.MicropumpConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.clock = reader.uint32();
                        break;
                    }
                case 2: {
                        message.amplitude = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MicropumpConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.MicropumpConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.MicropumpConfig} MicropumpConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MicropumpConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MicropumpConfig message.
         * @function verify
         * @memberof thimphuProtocol.MicropumpConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MicropumpConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.clock != null && message.hasOwnProperty("clock"))
                if (!$util.isInteger(message.clock))
                    return "clock: integer expected";
            if (message.amplitude != null && message.hasOwnProperty("amplitude"))
                if (!$util.isInteger(message.amplitude))
                    return "amplitude: integer expected";
            return null;
        };

        /**
         * Creates a MicropumpConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.MicropumpConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.MicropumpConfig} MicropumpConfig
         */
        MicropumpConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.MicropumpConfig)
                return object;
            let message = new $root.thimphuProtocol.MicropumpConfig();
            if (object.clock != null)
                message.clock = object.clock >>> 0;
            if (object.amplitude != null)
                message.amplitude = object.amplitude >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a MicropumpConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.MicropumpConfig
         * @static
         * @param {thimphuProtocol.MicropumpConfig} message MicropumpConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MicropumpConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.clock = 0;
                object.amplitude = 0;
            }
            if (message.clock != null && message.hasOwnProperty("clock"))
                object.clock = message.clock;
            if (message.amplitude != null && message.hasOwnProperty("amplitude"))
                object.amplitude = message.amplitude;
            return object;
        };

        /**
         * Converts this MicropumpConfig to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.MicropumpConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MicropumpConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MicropumpConfig
         * @function getTypeUrl
         * @memberof thimphuProtocol.MicropumpConfig
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MicropumpConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.MicropumpConfig";
        };

        return MicropumpConfig;
    })();

    thimphuProtocol.MotorPumpConfig = (function() {

        /**
         * Properties of a MotorPumpConfig.
         * @memberof thimphuProtocol
         * @interface IMotorPumpConfig
         * @property {number|null} [pwm] MotorPumpConfig pwm
         */

        /**
         * Constructs a new MotorPumpConfig.
         * @memberof thimphuProtocol
         * @classdesc Represents a MotorPumpConfig.
         * @implements IMotorPumpConfig
         * @constructor
         * @param {thimphuProtocol.IMotorPumpConfig=} [properties] Properties to set
         */
        function MotorPumpConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MotorPumpConfig pwm.
         * @member {number} pwm
         * @memberof thimphuProtocol.MotorPumpConfig
         * @instance
         */
        MotorPumpConfig.prototype.pwm = 0;

        /**
         * Creates a new MotorPumpConfig instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.MotorPumpConfig
         * @static
         * @param {thimphuProtocol.IMotorPumpConfig=} [properties] Properties to set
         * @returns {thimphuProtocol.MotorPumpConfig} MotorPumpConfig instance
         */
        MotorPumpConfig.create = function create(properties) {
            return new MotorPumpConfig(properties);
        };

        /**
         * Encodes the specified MotorPumpConfig message. Does not implicitly {@link thimphuProtocol.MotorPumpConfig.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.MotorPumpConfig
         * @static
         * @param {thimphuProtocol.IMotorPumpConfig} message MotorPumpConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MotorPumpConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pwm != null && Object.hasOwnProperty.call(message, "pwm"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.pwm);
            return writer;
        };

        /**
         * Encodes the specified MotorPumpConfig message, length delimited. Does not implicitly {@link thimphuProtocol.MotorPumpConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.MotorPumpConfig
         * @static
         * @param {thimphuProtocol.IMotorPumpConfig} message MotorPumpConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MotorPumpConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MotorPumpConfig message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.MotorPumpConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.MotorPumpConfig} MotorPumpConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MotorPumpConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.MotorPumpConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pwm = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MotorPumpConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.MotorPumpConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.MotorPumpConfig} MotorPumpConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MotorPumpConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MotorPumpConfig message.
         * @function verify
         * @memberof thimphuProtocol.MotorPumpConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MotorPumpConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pwm != null && message.hasOwnProperty("pwm"))
                if (!$util.isInteger(message.pwm))
                    return "pwm: integer expected";
            return null;
        };

        /**
         * Creates a MotorPumpConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.MotorPumpConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.MotorPumpConfig} MotorPumpConfig
         */
        MotorPumpConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.MotorPumpConfig)
                return object;
            let message = new $root.thimphuProtocol.MotorPumpConfig();
            if (object.pwm != null)
                message.pwm = object.pwm >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a MotorPumpConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.MotorPumpConfig
         * @static
         * @param {thimphuProtocol.MotorPumpConfig} message MotorPumpConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MotorPumpConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.pwm = 0;
            if (message.pwm != null && message.hasOwnProperty("pwm"))
                object.pwm = message.pwm;
            return object;
        };

        /**
         * Converts this MotorPumpConfig to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.MotorPumpConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MotorPumpConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MotorPumpConfig
         * @function getTypeUrl
         * @memberof thimphuProtocol.MotorPumpConfig
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MotorPumpConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.MotorPumpConfig";
        };

        return MotorPumpConfig;
    })();

    thimphuProtocol.VcselConfig = (function() {

        /**
         * Properties of a VcselConfig.
         * @memberof thimphuProtocol
         * @interface IVcselConfig
         * @property {number|null} [pwm] VcselConfig pwm
         */

        /**
         * Constructs a new VcselConfig.
         * @memberof thimphuProtocol
         * @classdesc Represents a VcselConfig.
         * @implements IVcselConfig
         * @constructor
         * @param {thimphuProtocol.IVcselConfig=} [properties] Properties to set
         */
        function VcselConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VcselConfig pwm.
         * @member {number} pwm
         * @memberof thimphuProtocol.VcselConfig
         * @instance
         */
        VcselConfig.prototype.pwm = 0;

        /**
         * Creates a new VcselConfig instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.VcselConfig
         * @static
         * @param {thimphuProtocol.IVcselConfig=} [properties] Properties to set
         * @returns {thimphuProtocol.VcselConfig} VcselConfig instance
         */
        VcselConfig.create = function create(properties) {
            return new VcselConfig(properties);
        };

        /**
         * Encodes the specified VcselConfig message. Does not implicitly {@link thimphuProtocol.VcselConfig.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.VcselConfig
         * @static
         * @param {thimphuProtocol.IVcselConfig} message VcselConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VcselConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pwm != null && Object.hasOwnProperty.call(message, "pwm"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.pwm);
            return writer;
        };

        /**
         * Encodes the specified VcselConfig message, length delimited. Does not implicitly {@link thimphuProtocol.VcselConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.VcselConfig
         * @static
         * @param {thimphuProtocol.IVcselConfig} message VcselConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VcselConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VcselConfig message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.VcselConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.VcselConfig} VcselConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VcselConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.VcselConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pwm = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VcselConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.VcselConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.VcselConfig} VcselConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VcselConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VcselConfig message.
         * @function verify
         * @memberof thimphuProtocol.VcselConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VcselConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pwm != null && message.hasOwnProperty("pwm"))
                if (!$util.isInteger(message.pwm))
                    return "pwm: integer expected";
            return null;
        };

        /**
         * Creates a VcselConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.VcselConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.VcselConfig} VcselConfig
         */
        VcselConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.VcselConfig)
                return object;
            let message = new $root.thimphuProtocol.VcselConfig();
            if (object.pwm != null)
                message.pwm = object.pwm >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a VcselConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.VcselConfig
         * @static
         * @param {thimphuProtocol.VcselConfig} message VcselConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VcselConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.pwm = 0;
            if (message.pwm != null && message.hasOwnProperty("pwm"))
                object.pwm = message.pwm;
            return object;
        };

        /**
         * Converts this VcselConfig to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.VcselConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VcselConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VcselConfig
         * @function getTypeUrl
         * @memberof thimphuProtocol.VcselConfig
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VcselConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.VcselConfig";
        };

        return VcselConfig;
    })();

    thimphuProtocol.StartCommand = (function() {

        /**
         * Properties of a StartCommand.
         * @memberof thimphuProtocol
         * @interface IStartCommand
         * @property {thimphuProtocol.DeviceId|null} [deviceId] StartCommand deviceId
         * @property {thimphuProtocol.IMicropumpConfig|null} [micropump] StartCommand micropump
         * @property {thimphuProtocol.IMotorPumpConfig|null} [motorPump] StartCommand motorPump
         * @property {thimphuProtocol.IVcselConfig|null} [vcsel] StartCommand vcsel
         */

        /**
         * Constructs a new StartCommand.
         * @memberof thimphuProtocol
         * @classdesc Represents a StartCommand.
         * @implements IStartCommand
         * @constructor
         * @param {thimphuProtocol.IStartCommand=} [properties] Properties to set
         */
        function StartCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StartCommand deviceId.
         * @member {thimphuProtocol.DeviceId} deviceId
         * @memberof thimphuProtocol.StartCommand
         * @instance
         */
        StartCommand.prototype.deviceId = 0;

        /**
         * StartCommand micropump.
         * @member {thimphuProtocol.IMicropumpConfig|null|undefined} micropump
         * @memberof thimphuProtocol.StartCommand
         * @instance
         */
        StartCommand.prototype.micropump = null;

        /**
         * StartCommand motorPump.
         * @member {thimphuProtocol.IMotorPumpConfig|null|undefined} motorPump
         * @memberof thimphuProtocol.StartCommand
         * @instance
         */
        StartCommand.prototype.motorPump = null;

        /**
         * StartCommand vcsel.
         * @member {thimphuProtocol.IVcselConfig|null|undefined} vcsel
         * @memberof thimphuProtocol.StartCommand
         * @instance
         */
        StartCommand.prototype.vcsel = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * StartCommand config.
         * @member {"micropump"|"motorPump"|"vcsel"|undefined} config
         * @memberof thimphuProtocol.StartCommand
         * @instance
         */
        Object.defineProperty(StartCommand.prototype, "config", {
            get: $util.oneOfGetter($oneOfFields = ["micropump", "motorPump", "vcsel"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new StartCommand instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.StartCommand
         * @static
         * @param {thimphuProtocol.IStartCommand=} [properties] Properties to set
         * @returns {thimphuProtocol.StartCommand} StartCommand instance
         */
        StartCommand.create = function create(properties) {
            return new StartCommand(properties);
        };

        /**
         * Encodes the specified StartCommand message. Does not implicitly {@link thimphuProtocol.StartCommand.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.StartCommand
         * @static
         * @param {thimphuProtocol.IStartCommand} message StartCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StartCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.deviceId);
            if (message.micropump != null && Object.hasOwnProperty.call(message, "micropump"))
                $root.thimphuProtocol.MicropumpConfig.encode(message.micropump, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.motorPump != null && Object.hasOwnProperty.call(message, "motorPump"))
                $root.thimphuProtocol.MotorPumpConfig.encode(message.motorPump, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.vcsel != null && Object.hasOwnProperty.call(message, "vcsel"))
                $root.thimphuProtocol.VcselConfig.encode(message.vcsel, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified StartCommand message, length delimited. Does not implicitly {@link thimphuProtocol.StartCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.StartCommand
         * @static
         * @param {thimphuProtocol.IStartCommand} message StartCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StartCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StartCommand message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.StartCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.StartCommand} StartCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StartCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.StartCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deviceId = reader.int32();
                        break;
                    }
                case 3: {
                        message.micropump = $root.thimphuProtocol.MicropumpConfig.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.motorPump = $root.thimphuProtocol.MotorPumpConfig.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.vcsel = $root.thimphuProtocol.VcselConfig.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StartCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.StartCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.StartCommand} StartCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StartCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StartCommand message.
         * @function verify
         * @memberof thimphuProtocol.StartCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StartCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                switch (message.deviceId) {
                default:
                    return "deviceId: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.micropump != null && message.hasOwnProperty("micropump")) {
                properties.config = 1;
                {
                    let error = $root.thimphuProtocol.MicropumpConfig.verify(message.micropump);
                    if (error)
                        return "micropump." + error;
                }
            }
            if (message.motorPump != null && message.hasOwnProperty("motorPump")) {
                if (properties.config === 1)
                    return "config: multiple values";
                properties.config = 1;
                {
                    let error = $root.thimphuProtocol.MotorPumpConfig.verify(message.motorPump);
                    if (error)
                        return "motorPump." + error;
                }
            }
            if (message.vcsel != null && message.hasOwnProperty("vcsel")) {
                if (properties.config === 1)
                    return "config: multiple values";
                properties.config = 1;
                {
                    let error = $root.thimphuProtocol.VcselConfig.verify(message.vcsel);
                    if (error)
                        return "vcsel." + error;
                }
            }
            return null;
        };

        /**
         * Creates a StartCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.StartCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.StartCommand} StartCommand
         */
        StartCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.StartCommand)
                return object;
            let message = new $root.thimphuProtocol.StartCommand();
            switch (object.deviceId) {
            default:
                if (typeof object.deviceId === "number") {
                    message.deviceId = object.deviceId;
                    break;
                }
                break;
            case "BOARD_1":
            case 0:
                message.deviceId = 0;
                break;
            case "HIH6120_1":
            case 1:
                message.deviceId = 1;
                break;
            case "MICROPUMP_1":
            case 2:
                message.deviceId = 2;
                break;
            case "MOTOR_PUMP_1":
            case 3:
                message.deviceId = 3;
                break;
            case "VCSEL_1":
            case 4:
                message.deviceId = 4;
                break;
            }
            if (object.micropump != null) {
                if (typeof object.micropump !== "object")
                    throw TypeError(".thimphuProtocol.StartCommand.micropump: object expected");
                message.micropump = $root.thimphuProtocol.MicropumpConfig.fromObject(object.micropump);
            }
            if (object.motorPump != null) {
                if (typeof object.motorPump !== "object")
                    throw TypeError(".thimphuProtocol.StartCommand.motorPump: object expected");
                message.motorPump = $root.thimphuProtocol.MotorPumpConfig.fromObject(object.motorPump);
            }
            if (object.vcsel != null) {
                if (typeof object.vcsel !== "object")
                    throw TypeError(".thimphuProtocol.StartCommand.vcsel: object expected");
                message.vcsel = $root.thimphuProtocol.VcselConfig.fromObject(object.vcsel);
            }
            return message;
        };

        /**
         * Creates a plain object from a StartCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.StartCommand
         * @static
         * @param {thimphuProtocol.StartCommand} message StartCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StartCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.deviceId = options.enums === String ? "BOARD_1" : 0;
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                object.deviceId = options.enums === String ? $root.thimphuProtocol.DeviceId[message.deviceId] === undefined ? message.deviceId : $root.thimphuProtocol.DeviceId[message.deviceId] : message.deviceId;
            if (message.micropump != null && message.hasOwnProperty("micropump")) {
                object.micropump = $root.thimphuProtocol.MicropumpConfig.toObject(message.micropump, options);
                if (options.oneofs)
                    object.config = "micropump";
            }
            if (message.motorPump != null && message.hasOwnProperty("motorPump")) {
                object.motorPump = $root.thimphuProtocol.MotorPumpConfig.toObject(message.motorPump, options);
                if (options.oneofs)
                    object.config = "motorPump";
            }
            if (message.vcsel != null && message.hasOwnProperty("vcsel")) {
                object.vcsel = $root.thimphuProtocol.VcselConfig.toObject(message.vcsel, options);
                if (options.oneofs)
                    object.config = "vcsel";
            }
            return object;
        };

        /**
         * Converts this StartCommand to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.StartCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StartCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StartCommand
         * @function getTypeUrl
         * @memberof thimphuProtocol.StartCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StartCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.StartCommand";
        };

        return StartCommand;
    })();

    thimphuProtocol.StopCommand = (function() {

        /**
         * Properties of a StopCommand.
         * @memberof thimphuProtocol
         * @interface IStopCommand
         * @property {thimphuProtocol.DeviceId|null} [deviceId] StopCommand deviceId
         */

        /**
         * Constructs a new StopCommand.
         * @memberof thimphuProtocol
         * @classdesc Represents a StopCommand.
         * @implements IStopCommand
         * @constructor
         * @param {thimphuProtocol.IStopCommand=} [properties] Properties to set
         */
        function StopCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StopCommand deviceId.
         * @member {thimphuProtocol.DeviceId} deviceId
         * @memberof thimphuProtocol.StopCommand
         * @instance
         */
        StopCommand.prototype.deviceId = 0;

        /**
         * Creates a new StopCommand instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.StopCommand
         * @static
         * @param {thimphuProtocol.IStopCommand=} [properties] Properties to set
         * @returns {thimphuProtocol.StopCommand} StopCommand instance
         */
        StopCommand.create = function create(properties) {
            return new StopCommand(properties);
        };

        /**
         * Encodes the specified StopCommand message. Does not implicitly {@link thimphuProtocol.StopCommand.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.StopCommand
         * @static
         * @param {thimphuProtocol.IStopCommand} message StopCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.deviceId);
            return writer;
        };

        /**
         * Encodes the specified StopCommand message, length delimited. Does not implicitly {@link thimphuProtocol.StopCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.StopCommand
         * @static
         * @param {thimphuProtocol.IStopCommand} message StopCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StopCommand message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.StopCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.StopCommand} StopCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.StopCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deviceId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StopCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.StopCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.StopCommand} StopCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StopCommand message.
         * @function verify
         * @memberof thimphuProtocol.StopCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StopCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                switch (message.deviceId) {
                default:
                    return "deviceId: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a StopCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.StopCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.StopCommand} StopCommand
         */
        StopCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.StopCommand)
                return object;
            let message = new $root.thimphuProtocol.StopCommand();
            switch (object.deviceId) {
            default:
                if (typeof object.deviceId === "number") {
                    message.deviceId = object.deviceId;
                    break;
                }
                break;
            case "BOARD_1":
            case 0:
                message.deviceId = 0;
                break;
            case "HIH6120_1":
            case 1:
                message.deviceId = 1;
                break;
            case "MICROPUMP_1":
            case 2:
                message.deviceId = 2;
                break;
            case "MOTOR_PUMP_1":
            case 3:
                message.deviceId = 3;
                break;
            case "VCSEL_1":
            case 4:
                message.deviceId = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a StopCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.StopCommand
         * @static
         * @param {thimphuProtocol.StopCommand} message StopCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StopCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.deviceId = options.enums === String ? "BOARD_1" : 0;
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                object.deviceId = options.enums === String ? $root.thimphuProtocol.DeviceId[message.deviceId] === undefined ? message.deviceId : $root.thimphuProtocol.DeviceId[message.deviceId] : message.deviceId;
            return object;
        };

        /**
         * Converts this StopCommand to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.StopCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StopCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StopCommand
         * @function getTypeUrl
         * @memberof thimphuProtocol.StopCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StopCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.StopCommand";
        };

        return StopCommand;
    })();

    thimphuProtocol.ConfigureCommand = (function() {

        /**
         * Properties of a ConfigureCommand.
         * @memberof thimphuProtocol
         * @interface IConfigureCommand
         */

        /**
         * Constructs a new ConfigureCommand.
         * @memberof thimphuProtocol
         * @classdesc Represents a ConfigureCommand.
         * @implements IConfigureCommand
         * @constructor
         * @param {thimphuProtocol.IConfigureCommand=} [properties] Properties to set
         */
        function ConfigureCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ConfigureCommand instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.ConfigureCommand
         * @static
         * @param {thimphuProtocol.IConfigureCommand=} [properties] Properties to set
         * @returns {thimphuProtocol.ConfigureCommand} ConfigureCommand instance
         */
        ConfigureCommand.create = function create(properties) {
            return new ConfigureCommand(properties);
        };

        /**
         * Encodes the specified ConfigureCommand message. Does not implicitly {@link thimphuProtocol.ConfigureCommand.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.ConfigureCommand
         * @static
         * @param {thimphuProtocol.IConfigureCommand} message ConfigureCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigureCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ConfigureCommand message, length delimited. Does not implicitly {@link thimphuProtocol.ConfigureCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.ConfigureCommand
         * @static
         * @param {thimphuProtocol.IConfigureCommand} message ConfigureCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigureCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfigureCommand message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.ConfigureCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.ConfigureCommand} ConfigureCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigureCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.ConfigureCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfigureCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.ConfigureCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.ConfigureCommand} ConfigureCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigureCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfigureCommand message.
         * @function verify
         * @memberof thimphuProtocol.ConfigureCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfigureCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ConfigureCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.ConfigureCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.ConfigureCommand} ConfigureCommand
         */
        ConfigureCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.ConfigureCommand)
                return object;
            return new $root.thimphuProtocol.ConfigureCommand();
        };

        /**
         * Creates a plain object from a ConfigureCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.ConfigureCommand
         * @static
         * @param {thimphuProtocol.ConfigureCommand} message ConfigureCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfigureCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ConfigureCommand to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.ConfigureCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfigureCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConfigureCommand
         * @function getTypeUrl
         * @memberof thimphuProtocol.ConfigureCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfigureCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.ConfigureCommand";
        };

        return ConfigureCommand;
    })();

    thimphuProtocol.GetPlatformIdCommand = (function() {

        /**
         * Properties of a GetPlatformIdCommand.
         * @memberof thimphuProtocol
         * @interface IGetPlatformIdCommand
         */

        /**
         * Constructs a new GetPlatformIdCommand.
         * @memberof thimphuProtocol
         * @classdesc Represents a GetPlatformIdCommand.
         * @implements IGetPlatformIdCommand
         * @constructor
         * @param {thimphuProtocol.IGetPlatformIdCommand=} [properties] Properties to set
         */
        function GetPlatformIdCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetPlatformIdCommand instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.GetPlatformIdCommand
         * @static
         * @param {thimphuProtocol.IGetPlatformIdCommand=} [properties] Properties to set
         * @returns {thimphuProtocol.GetPlatformIdCommand} GetPlatformIdCommand instance
         */
        GetPlatformIdCommand.create = function create(properties) {
            return new GetPlatformIdCommand(properties);
        };

        /**
         * Encodes the specified GetPlatformIdCommand message. Does not implicitly {@link thimphuProtocol.GetPlatformIdCommand.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.GetPlatformIdCommand
         * @static
         * @param {thimphuProtocol.IGetPlatformIdCommand} message GetPlatformIdCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlatformIdCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetPlatformIdCommand message, length delimited. Does not implicitly {@link thimphuProtocol.GetPlatformIdCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.GetPlatformIdCommand
         * @static
         * @param {thimphuProtocol.IGetPlatformIdCommand} message GetPlatformIdCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlatformIdCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetPlatformIdCommand message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.GetPlatformIdCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.GetPlatformIdCommand} GetPlatformIdCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlatformIdCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.GetPlatformIdCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetPlatformIdCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.GetPlatformIdCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.GetPlatformIdCommand} GetPlatformIdCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlatformIdCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetPlatformIdCommand message.
         * @function verify
         * @memberof thimphuProtocol.GetPlatformIdCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetPlatformIdCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetPlatformIdCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.GetPlatformIdCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.GetPlatformIdCommand} GetPlatformIdCommand
         */
        GetPlatformIdCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.GetPlatformIdCommand)
                return object;
            return new $root.thimphuProtocol.GetPlatformIdCommand();
        };

        /**
         * Creates a plain object from a GetPlatformIdCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.GetPlatformIdCommand
         * @static
         * @param {thimphuProtocol.GetPlatformIdCommand} message GetPlatformIdCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetPlatformIdCommand.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetPlatformIdCommand to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.GetPlatformIdCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetPlatformIdCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetPlatformIdCommand
         * @function getTypeUrl
         * @memberof thimphuProtocol.GetPlatformIdCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetPlatformIdCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.GetPlatformIdCommand";
        };

        return GetPlatformIdCommand;
    })();

    thimphuProtocol.ReadMemCommand = (function() {

        /**
         * Properties of a ReadMemCommand.
         * @memberof thimphuProtocol
         * @interface IReadMemCommand
         * @property {thimphuProtocol.MemId|null} [memId] ReadMemCommand memId
         * @property {number|null} [offset] ReadMemCommand offset
         * @property {number|null} [size] ReadMemCommand size
         */

        /**
         * Constructs a new ReadMemCommand.
         * @memberof thimphuProtocol
         * @classdesc Represents a ReadMemCommand.
         * @implements IReadMemCommand
         * @constructor
         * @param {thimphuProtocol.IReadMemCommand=} [properties] Properties to set
         */
        function ReadMemCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReadMemCommand memId.
         * @member {thimphuProtocol.MemId} memId
         * @memberof thimphuProtocol.ReadMemCommand
         * @instance
         */
        ReadMemCommand.prototype.memId = 0;

        /**
         * ReadMemCommand offset.
         * @member {number} offset
         * @memberof thimphuProtocol.ReadMemCommand
         * @instance
         */
        ReadMemCommand.prototype.offset = 0;

        /**
         * ReadMemCommand size.
         * @member {number} size
         * @memberof thimphuProtocol.ReadMemCommand
         * @instance
         */
        ReadMemCommand.prototype.size = 0;

        /**
         * Creates a new ReadMemCommand instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.ReadMemCommand
         * @static
         * @param {thimphuProtocol.IReadMemCommand=} [properties] Properties to set
         * @returns {thimphuProtocol.ReadMemCommand} ReadMemCommand instance
         */
        ReadMemCommand.create = function create(properties) {
            return new ReadMemCommand(properties);
        };

        /**
         * Encodes the specified ReadMemCommand message. Does not implicitly {@link thimphuProtocol.ReadMemCommand.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.ReadMemCommand
         * @static
         * @param {thimphuProtocol.IReadMemCommand} message ReadMemCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadMemCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.memId != null && Object.hasOwnProperty.call(message, "memId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.memId);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.offset);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.size);
            return writer;
        };

        /**
         * Encodes the specified ReadMemCommand message, length delimited. Does not implicitly {@link thimphuProtocol.ReadMemCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.ReadMemCommand
         * @static
         * @param {thimphuProtocol.IReadMemCommand} message ReadMemCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadMemCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReadMemCommand message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.ReadMemCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.ReadMemCommand} ReadMemCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadMemCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.ReadMemCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.memId = reader.int32();
                        break;
                    }
                case 2: {
                        message.offset = reader.uint32();
                        break;
                    }
                case 3: {
                        message.size = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReadMemCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.ReadMemCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.ReadMemCommand} ReadMemCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadMemCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReadMemCommand message.
         * @function verify
         * @memberof thimphuProtocol.ReadMemCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReadMemCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.memId != null && message.hasOwnProperty("memId"))
                switch (message.memId) {
                default:
                    return "memId: enum value expected";
                case 0:
                    break;
                }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size))
                    return "size: integer expected";
            return null;
        };

        /**
         * Creates a ReadMemCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.ReadMemCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.ReadMemCommand} ReadMemCommand
         */
        ReadMemCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.ReadMemCommand)
                return object;
            let message = new $root.thimphuProtocol.ReadMemCommand();
            switch (object.memId) {
            default:
                if (typeof object.memId === "number") {
                    message.memId = object.memId;
                    break;
                }
                break;
            case "EEPROM":
            case 0:
                message.memId = 0;
                break;
            }
            if (object.offset != null)
                message.offset = object.offset >>> 0;
            if (object.size != null)
                message.size = object.size >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ReadMemCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.ReadMemCommand
         * @static
         * @param {thimphuProtocol.ReadMemCommand} message ReadMemCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReadMemCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.memId = options.enums === String ? "EEPROM" : 0;
                object.offset = 0;
                object.size = 0;
            }
            if (message.memId != null && message.hasOwnProperty("memId"))
                object.memId = options.enums === String ? $root.thimphuProtocol.MemId[message.memId] === undefined ? message.memId : $root.thimphuProtocol.MemId[message.memId] : message.memId;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.size != null && message.hasOwnProperty("size"))
                object.size = message.size;
            return object;
        };

        /**
         * Converts this ReadMemCommand to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.ReadMemCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReadMemCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReadMemCommand
         * @function getTypeUrl
         * @memberof thimphuProtocol.ReadMemCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReadMemCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.ReadMemCommand";
        };

        return ReadMemCommand;
    })();

    thimphuProtocol.WriteMemCommand = (function() {

        /**
         * Properties of a WriteMemCommand.
         * @memberof thimphuProtocol
         * @interface IWriteMemCommand
         * @property {thimphuProtocol.MemId|null} [memId] WriteMemCommand memId
         * @property {number|null} [offset] WriteMemCommand offset
         * @property {Uint8Array|null} [data] WriteMemCommand data
         */

        /**
         * Constructs a new WriteMemCommand.
         * @memberof thimphuProtocol
         * @classdesc Represents a WriteMemCommand.
         * @implements IWriteMemCommand
         * @constructor
         * @param {thimphuProtocol.IWriteMemCommand=} [properties] Properties to set
         */
        function WriteMemCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WriteMemCommand memId.
         * @member {thimphuProtocol.MemId} memId
         * @memberof thimphuProtocol.WriteMemCommand
         * @instance
         */
        WriteMemCommand.prototype.memId = 0;

        /**
         * WriteMemCommand offset.
         * @member {number} offset
         * @memberof thimphuProtocol.WriteMemCommand
         * @instance
         */
        WriteMemCommand.prototype.offset = 0;

        /**
         * WriteMemCommand data.
         * @member {Uint8Array} data
         * @memberof thimphuProtocol.WriteMemCommand
         * @instance
         */
        WriteMemCommand.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new WriteMemCommand instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.WriteMemCommand
         * @static
         * @param {thimphuProtocol.IWriteMemCommand=} [properties] Properties to set
         * @returns {thimphuProtocol.WriteMemCommand} WriteMemCommand instance
         */
        WriteMemCommand.create = function create(properties) {
            return new WriteMemCommand(properties);
        };

        /**
         * Encodes the specified WriteMemCommand message. Does not implicitly {@link thimphuProtocol.WriteMemCommand.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.WriteMemCommand
         * @static
         * @param {thimphuProtocol.IWriteMemCommand} message WriteMemCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WriteMemCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.memId != null && Object.hasOwnProperty.call(message, "memId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.memId);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.offset);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified WriteMemCommand message, length delimited. Does not implicitly {@link thimphuProtocol.WriteMemCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.WriteMemCommand
         * @static
         * @param {thimphuProtocol.IWriteMemCommand} message WriteMemCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WriteMemCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WriteMemCommand message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.WriteMemCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.WriteMemCommand} WriteMemCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WriteMemCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.WriteMemCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.memId = reader.int32();
                        break;
                    }
                case 2: {
                        message.offset = reader.uint32();
                        break;
                    }
                case 3: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WriteMemCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.WriteMemCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.WriteMemCommand} WriteMemCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WriteMemCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WriteMemCommand message.
         * @function verify
         * @memberof thimphuProtocol.WriteMemCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WriteMemCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.memId != null && message.hasOwnProperty("memId"))
                switch (message.memId) {
                default:
                    return "memId: enum value expected";
                case 0:
                    break;
                }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a WriteMemCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.WriteMemCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.WriteMemCommand} WriteMemCommand
         */
        WriteMemCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.WriteMemCommand)
                return object;
            let message = new $root.thimphuProtocol.WriteMemCommand();
            switch (object.memId) {
            default:
                if (typeof object.memId === "number") {
                    message.memId = object.memId;
                    break;
                }
                break;
            case "EEPROM":
            case 0:
                message.memId = 0;
                break;
            }
            if (object.offset != null)
                message.offset = object.offset >>> 0;
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a WriteMemCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.WriteMemCommand
         * @static
         * @param {thimphuProtocol.WriteMemCommand} message WriteMemCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WriteMemCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.memId = options.enums === String ? "EEPROM" : 0;
                object.offset = 0;
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.memId != null && message.hasOwnProperty("memId"))
                object.memId = options.enums === String ? $root.thimphuProtocol.MemId[message.memId] === undefined ? message.memId : $root.thimphuProtocol.MemId[message.memId] : message.memId;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this WriteMemCommand to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.WriteMemCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WriteMemCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WriteMemCommand
         * @function getTypeUrl
         * @memberof thimphuProtocol.WriteMemCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WriteMemCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.WriteMemCommand";
        };

        return WriteMemCommand;
    })();

    thimphuProtocol.GetMemInfosCommand = (function() {

        /**
         * Properties of a GetMemInfosCommand.
         * @memberof thimphuProtocol
         * @interface IGetMemInfosCommand
         * @property {thimphuProtocol.MemId|null} [memId] GetMemInfosCommand memId
         */

        /**
         * Constructs a new GetMemInfosCommand.
         * @memberof thimphuProtocol
         * @classdesc Represents a GetMemInfosCommand.
         * @implements IGetMemInfosCommand
         * @constructor
         * @param {thimphuProtocol.IGetMemInfosCommand=} [properties] Properties to set
         */
        function GetMemInfosCommand(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMemInfosCommand memId.
         * @member {thimphuProtocol.MemId} memId
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @instance
         */
        GetMemInfosCommand.prototype.memId = 0;

        /**
         * Creates a new GetMemInfosCommand instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @static
         * @param {thimphuProtocol.IGetMemInfosCommand=} [properties] Properties to set
         * @returns {thimphuProtocol.GetMemInfosCommand} GetMemInfosCommand instance
         */
        GetMemInfosCommand.create = function create(properties) {
            return new GetMemInfosCommand(properties);
        };

        /**
         * Encodes the specified GetMemInfosCommand message. Does not implicitly {@link thimphuProtocol.GetMemInfosCommand.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @static
         * @param {thimphuProtocol.IGetMemInfosCommand} message GetMemInfosCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMemInfosCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.memId != null && Object.hasOwnProperty.call(message, "memId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.memId);
            return writer;
        };

        /**
         * Encodes the specified GetMemInfosCommand message, length delimited. Does not implicitly {@link thimphuProtocol.GetMemInfosCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @static
         * @param {thimphuProtocol.IGetMemInfosCommand} message GetMemInfosCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMemInfosCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMemInfosCommand message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.GetMemInfosCommand} GetMemInfosCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMemInfosCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.GetMemInfosCommand();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.memId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMemInfosCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.GetMemInfosCommand} GetMemInfosCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMemInfosCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMemInfosCommand message.
         * @function verify
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMemInfosCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.memId != null && message.hasOwnProperty("memId"))
                switch (message.memId) {
                default:
                    return "memId: enum value expected";
                case 0:
                    break;
                }
            return null;
        };

        /**
         * Creates a GetMemInfosCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.GetMemInfosCommand} GetMemInfosCommand
         */
        GetMemInfosCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.GetMemInfosCommand)
                return object;
            let message = new $root.thimphuProtocol.GetMemInfosCommand();
            switch (object.memId) {
            default:
                if (typeof object.memId === "number") {
                    message.memId = object.memId;
                    break;
                }
                break;
            case "EEPROM":
            case 0:
                message.memId = 0;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a GetMemInfosCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @static
         * @param {thimphuProtocol.GetMemInfosCommand} message GetMemInfosCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMemInfosCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.memId = options.enums === String ? "EEPROM" : 0;
            if (message.memId != null && message.hasOwnProperty("memId"))
                object.memId = options.enums === String ? $root.thimphuProtocol.MemId[message.memId] === undefined ? message.memId : $root.thimphuProtocol.MemId[message.memId] : message.memId;
            return object;
        };

        /**
         * Converts this GetMemInfosCommand to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMemInfosCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMemInfosCommand
         * @function getTypeUrl
         * @memberof thimphuProtocol.GetMemInfosCommand
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMemInfosCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.GetMemInfosCommand";
        };

        return GetMemInfosCommand;
    })();

    thimphuProtocol.DataTemperatureHih6120 = (function() {

        /**
         * Properties of a DataTemperatureHih6120.
         * @memberof thimphuProtocol
         * @interface IDataTemperatureHih6120
         * @property {number|null} [rawHumidity] DataTemperatureHih6120 rawHumidity
         * @property {number|null} [rawTemperature] DataTemperatureHih6120 rawTemperature
         */

        /**
         * Constructs a new DataTemperatureHih6120.
         * @memberof thimphuProtocol
         * @classdesc Represents a DataTemperatureHih6120.
         * @implements IDataTemperatureHih6120
         * @constructor
         * @param {thimphuProtocol.IDataTemperatureHih6120=} [properties] Properties to set
         */
        function DataTemperatureHih6120(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataTemperatureHih6120 rawHumidity.
         * @member {number} rawHumidity
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @instance
         */
        DataTemperatureHih6120.prototype.rawHumidity = 0;

        /**
         * DataTemperatureHih6120 rawTemperature.
         * @member {number} rawTemperature
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @instance
         */
        DataTemperatureHih6120.prototype.rawTemperature = 0;

        /**
         * Creates a new DataTemperatureHih6120 instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @static
         * @param {thimphuProtocol.IDataTemperatureHih6120=} [properties] Properties to set
         * @returns {thimphuProtocol.DataTemperatureHih6120} DataTemperatureHih6120 instance
         */
        DataTemperatureHih6120.create = function create(properties) {
            return new DataTemperatureHih6120(properties);
        };

        /**
         * Encodes the specified DataTemperatureHih6120 message. Does not implicitly {@link thimphuProtocol.DataTemperatureHih6120.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @static
         * @param {thimphuProtocol.IDataTemperatureHih6120} message DataTemperatureHih6120 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataTemperatureHih6120.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rawHumidity != null && Object.hasOwnProperty.call(message, "rawHumidity"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.rawHumidity);
            if (message.rawTemperature != null && Object.hasOwnProperty.call(message, "rawTemperature"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.rawTemperature);
            return writer;
        };

        /**
         * Encodes the specified DataTemperatureHih6120 message, length delimited. Does not implicitly {@link thimphuProtocol.DataTemperatureHih6120.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @static
         * @param {thimphuProtocol.IDataTemperatureHih6120} message DataTemperatureHih6120 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataTemperatureHih6120.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataTemperatureHih6120 message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.DataTemperatureHih6120} DataTemperatureHih6120
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataTemperatureHih6120.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.DataTemperatureHih6120();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rawHumidity = reader.uint32();
                        break;
                    }
                case 2: {
                        message.rawTemperature = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataTemperatureHih6120 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.DataTemperatureHih6120} DataTemperatureHih6120
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataTemperatureHih6120.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataTemperatureHih6120 message.
         * @function verify
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataTemperatureHih6120.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rawHumidity != null && message.hasOwnProperty("rawHumidity"))
                if (!$util.isInteger(message.rawHumidity))
                    return "rawHumidity: integer expected";
            if (message.rawTemperature != null && message.hasOwnProperty("rawTemperature"))
                if (!$util.isInteger(message.rawTemperature))
                    return "rawTemperature: integer expected";
            return null;
        };

        /**
         * Creates a DataTemperatureHih6120 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.DataTemperatureHih6120} DataTemperatureHih6120
         */
        DataTemperatureHih6120.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.DataTemperatureHih6120)
                return object;
            let message = new $root.thimphuProtocol.DataTemperatureHih6120();
            if (object.rawHumidity != null)
                message.rawHumidity = object.rawHumidity >>> 0;
            if (object.rawTemperature != null)
                message.rawTemperature = object.rawTemperature >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a DataTemperatureHih6120 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @static
         * @param {thimphuProtocol.DataTemperatureHih6120} message DataTemperatureHih6120
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataTemperatureHih6120.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.rawHumidity = 0;
                object.rawTemperature = 0;
            }
            if (message.rawHumidity != null && message.hasOwnProperty("rawHumidity"))
                object.rawHumidity = message.rawHumidity;
            if (message.rawTemperature != null && message.hasOwnProperty("rawTemperature"))
                object.rawTemperature = message.rawTemperature;
            return object;
        };

        /**
         * Converts this DataTemperatureHih6120 to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataTemperatureHih6120.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataTemperatureHih6120
         * @function getTypeUrl
         * @memberof thimphuProtocol.DataTemperatureHih6120
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataTemperatureHih6120.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.DataTemperatureHih6120";
        };

        return DataTemperatureHih6120;
    })();

    thimphuProtocol.DataEvent = (function() {

        /**
         * Properties of a DataEvent.
         * @memberof thimphuProtocol
         * @interface IDataEvent
         * @property {thimphuProtocol.DeviceId|null} [deviceId] DataEvent deviceId
         * @property {thimphuProtocol.IDataTemperatureHih6120|null} [temperatureHih6120] DataEvent temperatureHih6120
         */

        /**
         * Constructs a new DataEvent.
         * @memberof thimphuProtocol
         * @classdesc Represents a DataEvent.
         * @implements IDataEvent
         * @constructor
         * @param {thimphuProtocol.IDataEvent=} [properties] Properties to set
         */
        function DataEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataEvent deviceId.
         * @member {thimphuProtocol.DeviceId} deviceId
         * @memberof thimphuProtocol.DataEvent
         * @instance
         */
        DataEvent.prototype.deviceId = 0;

        /**
         * DataEvent temperatureHih6120.
         * @member {thimphuProtocol.IDataTemperatureHih6120|null|undefined} temperatureHih6120
         * @memberof thimphuProtocol.DataEvent
         * @instance
         */
        DataEvent.prototype.temperatureHih6120 = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * DataEvent data.
         * @member {"temperatureHih6120"|undefined} data
         * @memberof thimphuProtocol.DataEvent
         * @instance
         */
        Object.defineProperty(DataEvent.prototype, "data", {
            get: $util.oneOfGetter($oneOfFields = ["temperatureHih6120"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DataEvent instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.DataEvent
         * @static
         * @param {thimphuProtocol.IDataEvent=} [properties] Properties to set
         * @returns {thimphuProtocol.DataEvent} DataEvent instance
         */
        DataEvent.create = function create(properties) {
            return new DataEvent(properties);
        };

        /**
         * Encodes the specified DataEvent message. Does not implicitly {@link thimphuProtocol.DataEvent.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.DataEvent
         * @static
         * @param {thimphuProtocol.IDataEvent} message DataEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.deviceId);
            if (message.temperatureHih6120 != null && Object.hasOwnProperty.call(message, "temperatureHih6120"))
                $root.thimphuProtocol.DataTemperatureHih6120.encode(message.temperatureHih6120, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DataEvent message, length delimited. Does not implicitly {@link thimphuProtocol.DataEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.DataEvent
         * @static
         * @param {thimphuProtocol.IDataEvent} message DataEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DataEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DataEvent message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.DataEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.DataEvent} DataEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.DataEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deviceId = reader.int32();
                        break;
                    }
                case 2: {
                        message.temperatureHih6120 = $root.thimphuProtocol.DataTemperatureHih6120.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DataEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.DataEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.DataEvent} DataEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DataEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DataEvent message.
         * @function verify
         * @memberof thimphuProtocol.DataEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                switch (message.deviceId) {
                default:
                    return "deviceId: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.temperatureHih6120 != null && message.hasOwnProperty("temperatureHih6120")) {
                properties.data = 1;
                {
                    let error = $root.thimphuProtocol.DataTemperatureHih6120.verify(message.temperatureHih6120);
                    if (error)
                        return "temperatureHih6120." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DataEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.DataEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.DataEvent} DataEvent
         */
        DataEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.DataEvent)
                return object;
            let message = new $root.thimphuProtocol.DataEvent();
            switch (object.deviceId) {
            default:
                if (typeof object.deviceId === "number") {
                    message.deviceId = object.deviceId;
                    break;
                }
                break;
            case "BOARD_1":
            case 0:
                message.deviceId = 0;
                break;
            case "HIH6120_1":
            case 1:
                message.deviceId = 1;
                break;
            case "MICROPUMP_1":
            case 2:
                message.deviceId = 2;
                break;
            case "MOTOR_PUMP_1":
            case 3:
                message.deviceId = 3;
                break;
            case "VCSEL_1":
            case 4:
                message.deviceId = 4;
                break;
            }
            if (object.temperatureHih6120 != null) {
                if (typeof object.temperatureHih6120 !== "object")
                    throw TypeError(".thimphuProtocol.DataEvent.temperatureHih6120: object expected");
                message.temperatureHih6120 = $root.thimphuProtocol.DataTemperatureHih6120.fromObject(object.temperatureHih6120);
            }
            return message;
        };

        /**
         * Creates a plain object from a DataEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.DataEvent
         * @static
         * @param {thimphuProtocol.DataEvent} message DataEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.deviceId = options.enums === String ? "BOARD_1" : 0;
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                object.deviceId = options.enums === String ? $root.thimphuProtocol.DeviceId[message.deviceId] === undefined ? message.deviceId : $root.thimphuProtocol.DeviceId[message.deviceId] : message.deviceId;
            if (message.temperatureHih6120 != null && message.hasOwnProperty("temperatureHih6120")) {
                object.temperatureHih6120 = $root.thimphuProtocol.DataTemperatureHih6120.toObject(message.temperatureHih6120, options);
                if (options.oneofs)
                    object.data = "temperatureHih6120";
            }
            return object;
        };

        /**
         * Converts this DataEvent to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.DataEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DataEvent
         * @function getTypeUrl
         * @memberof thimphuProtocol.DataEvent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DataEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.DataEvent";
        };

        return DataEvent;
    })();

    thimphuProtocol.StatusEvent = (function() {

        /**
         * Properties of a StatusEvent.
         * @memberof thimphuProtocol
         * @interface IStatusEvent
         * @property {thimphuProtocol.DeviceId|null} [deviceId] StatusEvent deviceId
         * @property {thimphuProtocol.StatusEvent.State|null} [state] StatusEvent state
         */

        /**
         * Constructs a new StatusEvent.
         * @memberof thimphuProtocol
         * @classdesc Represents a StatusEvent.
         * @implements IStatusEvent
         * @constructor
         * @param {thimphuProtocol.IStatusEvent=} [properties] Properties to set
         */
        function StatusEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatusEvent deviceId.
         * @member {thimphuProtocol.DeviceId} deviceId
         * @memberof thimphuProtocol.StatusEvent
         * @instance
         */
        StatusEvent.prototype.deviceId = 0;

        /**
         * StatusEvent state.
         * @member {thimphuProtocol.StatusEvent.State} state
         * @memberof thimphuProtocol.StatusEvent
         * @instance
         */
        StatusEvent.prototype.state = 0;

        /**
         * Creates a new StatusEvent instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.StatusEvent
         * @static
         * @param {thimphuProtocol.IStatusEvent=} [properties] Properties to set
         * @returns {thimphuProtocol.StatusEvent} StatusEvent instance
         */
        StatusEvent.create = function create(properties) {
            return new StatusEvent(properties);
        };

        /**
         * Encodes the specified StatusEvent message. Does not implicitly {@link thimphuProtocol.StatusEvent.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.StatusEvent
         * @static
         * @param {thimphuProtocol.IStatusEvent} message StatusEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.deviceId);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.state);
            return writer;
        };

        /**
         * Encodes the specified StatusEvent message, length delimited. Does not implicitly {@link thimphuProtocol.StatusEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.StatusEvent
         * @static
         * @param {thimphuProtocol.IStatusEvent} message StatusEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusEvent message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.StatusEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.StatusEvent} StatusEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.StatusEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deviceId = reader.int32();
                        break;
                    }
                case 2: {
                        message.state = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.StatusEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.StatusEvent} StatusEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusEvent message.
         * @function verify
         * @memberof thimphuProtocol.StatusEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                switch (message.deviceId) {
                default:
                    return "deviceId: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates a StatusEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.StatusEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.StatusEvent} StatusEvent
         */
        StatusEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.StatusEvent)
                return object;
            let message = new $root.thimphuProtocol.StatusEvent();
            switch (object.deviceId) {
            default:
                if (typeof object.deviceId === "number") {
                    message.deviceId = object.deviceId;
                    break;
                }
                break;
            case "BOARD_1":
            case 0:
                message.deviceId = 0;
                break;
            case "HIH6120_1":
            case 1:
                message.deviceId = 1;
                break;
            case "MICROPUMP_1":
            case 2:
                message.deviceId = 2;
                break;
            case "MOTOR_PUMP_1":
            case 3:
                message.deviceId = 3;
                break;
            case "VCSEL_1":
            case 4:
                message.deviceId = 4;
                break;
            }
            switch (object.state) {
            default:
                if (typeof object.state === "number") {
                    message.state = object.state;
                    break;
                }
                break;
            case "STARTED":
            case 0:
                message.state = 0;
                break;
            case "STOPPED":
            case 1:
                message.state = 1;
                break;
            case "ERROR":
            case 2:
                message.state = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a StatusEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.StatusEvent
         * @static
         * @param {thimphuProtocol.StatusEvent} message StatusEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.deviceId = options.enums === String ? "BOARD_1" : 0;
                object.state = options.enums === String ? "STARTED" : 0;
            }
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                object.deviceId = options.enums === String ? $root.thimphuProtocol.DeviceId[message.deviceId] === undefined ? message.deviceId : $root.thimphuProtocol.DeviceId[message.deviceId] : message.deviceId;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.thimphuProtocol.StatusEvent.State[message.state] === undefined ? message.state : $root.thimphuProtocol.StatusEvent.State[message.state] : message.state;
            return object;
        };

        /**
         * Converts this StatusEvent to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.StatusEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StatusEvent
         * @function getTypeUrl
         * @memberof thimphuProtocol.StatusEvent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StatusEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.StatusEvent";
        };

        /**
         * State enum.
         * @name thimphuProtocol.StatusEvent.State
         * @enum {number}
         * @property {number} STARTED=0 STARTED value
         * @property {number} STOPPED=1 STOPPED value
         * @property {number} ERROR=2 ERROR value
         */
        StatusEvent.State = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "STARTED"] = 0;
            values[valuesById[1] = "STOPPED"] = 1;
            values[valuesById[2] = "ERROR"] = 2;
            return values;
        })();

        return StatusEvent;
    })();

    thimphuProtocol.AckResult = (function() {

        /**
         * Properties of an AckResult.
         * @memberof thimphuProtocol
         * @interface IAckResult
         * @property {number|null} [hostMsgCnt] AckResult hostMsgCnt
         * @property {thimphuProtocol.AckResult.Status|null} [status] AckResult status
         */

        /**
         * Constructs a new AckResult.
         * @memberof thimphuProtocol
         * @classdesc Represents an AckResult.
         * @implements IAckResult
         * @constructor
         * @param {thimphuProtocol.IAckResult=} [properties] Properties to set
         */
        function AckResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AckResult hostMsgCnt.
         * @member {number} hostMsgCnt
         * @memberof thimphuProtocol.AckResult
         * @instance
         */
        AckResult.prototype.hostMsgCnt = 0;

        /**
         * AckResult status.
         * @member {thimphuProtocol.AckResult.Status} status
         * @memberof thimphuProtocol.AckResult
         * @instance
         */
        AckResult.prototype.status = 0;

        /**
         * Creates a new AckResult instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.AckResult
         * @static
         * @param {thimphuProtocol.IAckResult=} [properties] Properties to set
         * @returns {thimphuProtocol.AckResult} AckResult instance
         */
        AckResult.create = function create(properties) {
            return new AckResult(properties);
        };

        /**
         * Encodes the specified AckResult message. Does not implicitly {@link thimphuProtocol.AckResult.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.AckResult
         * @static
         * @param {thimphuProtocol.IAckResult} message AckResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AckResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hostMsgCnt != null && Object.hasOwnProperty.call(message, "hostMsgCnt"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.hostMsgCnt);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified AckResult message, length delimited. Does not implicitly {@link thimphuProtocol.AckResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.AckResult
         * @static
         * @param {thimphuProtocol.IAckResult} message AckResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AckResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AckResult message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.AckResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.AckResult} AckResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AckResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.AckResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hostMsgCnt = reader.uint32();
                        break;
                    }
                case 2: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AckResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.AckResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.AckResult} AckResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AckResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AckResult message.
         * @function verify
         * @memberof thimphuProtocol.AckResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AckResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hostMsgCnt != null && message.hasOwnProperty("hostMsgCnt"))
                if (!$util.isInteger(message.hostMsgCnt))
                    return "hostMsgCnt: integer expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates an AckResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.AckResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.AckResult} AckResult
         */
        AckResult.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.AckResult)
                return object;
            let message = new $root.thimphuProtocol.AckResult();
            if (object.hostMsgCnt != null)
                message.hostMsgCnt = object.hostMsgCnt >>> 0;
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "ACK_SUCCESS":
            case 0:
                message.status = 0;
                break;
            case "ACK_UNKNOWN_ERROR":
            case 1:
                message.status = 1;
                break;
            case "ACK_CMD_ERROR":
            case 2:
                message.status = 2;
                break;
            case "ACK_DRIVER_ERROR":
            case 3:
                message.status = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an AckResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.AckResult
         * @static
         * @param {thimphuProtocol.AckResult} message AckResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AckResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.hostMsgCnt = 0;
                object.status = options.enums === String ? "ACK_SUCCESS" : 0;
            }
            if (message.hostMsgCnt != null && message.hasOwnProperty("hostMsgCnt"))
                object.hostMsgCnt = message.hostMsgCnt;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.thimphuProtocol.AckResult.Status[message.status] === undefined ? message.status : $root.thimphuProtocol.AckResult.Status[message.status] : message.status;
            return object;
        };

        /**
         * Converts this AckResult to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.AckResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AckResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AckResult
         * @function getTypeUrl
         * @memberof thimphuProtocol.AckResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AckResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.AckResult";
        };

        /**
         * Status enum.
         * @name thimphuProtocol.AckResult.Status
         * @enum {number}
         * @property {number} ACK_SUCCESS=0 ACK_SUCCESS value
         * @property {number} ACK_UNKNOWN_ERROR=1 ACK_UNKNOWN_ERROR value
         * @property {number} ACK_CMD_ERROR=2 ACK_CMD_ERROR value
         * @property {number} ACK_DRIVER_ERROR=3 ACK_DRIVER_ERROR value
         */
        AckResult.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ACK_SUCCESS"] = 0;
            values[valuesById[1] = "ACK_UNKNOWN_ERROR"] = 1;
            values[valuesById[2] = "ACK_CMD_ERROR"] = 2;
            values[valuesById[3] = "ACK_DRIVER_ERROR"] = 3;
            return values;
        })();

        return AckResult;
    })();

    thimphuProtocol.PlatformIdResult = (function() {

        /**
         * Properties of a PlatformIdResult.
         * @memberof thimphuProtocol
         * @interface IPlatformIdResult
         * @property {thimphuProtocol.IAckResult|null} [ack] PlatformIdResult ack
         * @property {string|null} [id] PlatformIdResult id
         * @property {string|null} [version] PlatformIdResult version
         * @property {string|null} [serialNumber] PlatformIdResult serialNumber
         */

        /**
         * Constructs a new PlatformIdResult.
         * @memberof thimphuProtocol
         * @classdesc Represents a PlatformIdResult.
         * @implements IPlatformIdResult
         * @constructor
         * @param {thimphuProtocol.IPlatformIdResult=} [properties] Properties to set
         */
        function PlatformIdResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlatformIdResult ack.
         * @member {thimphuProtocol.IAckResult|null|undefined} ack
         * @memberof thimphuProtocol.PlatformIdResult
         * @instance
         */
        PlatformIdResult.prototype.ack = null;

        /**
         * PlatformIdResult id.
         * @member {string} id
         * @memberof thimphuProtocol.PlatformIdResult
         * @instance
         */
        PlatformIdResult.prototype.id = "";

        /**
         * PlatformIdResult version.
         * @member {string} version
         * @memberof thimphuProtocol.PlatformIdResult
         * @instance
         */
        PlatformIdResult.prototype.version = "";

        /**
         * PlatformIdResult serialNumber.
         * @member {string} serialNumber
         * @memberof thimphuProtocol.PlatformIdResult
         * @instance
         */
        PlatformIdResult.prototype.serialNumber = "";

        /**
         * Creates a new PlatformIdResult instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.PlatformIdResult
         * @static
         * @param {thimphuProtocol.IPlatformIdResult=} [properties] Properties to set
         * @returns {thimphuProtocol.PlatformIdResult} PlatformIdResult instance
         */
        PlatformIdResult.create = function create(properties) {
            return new PlatformIdResult(properties);
        };

        /**
         * Encodes the specified PlatformIdResult message. Does not implicitly {@link thimphuProtocol.PlatformIdResult.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.PlatformIdResult
         * @static
         * @param {thimphuProtocol.IPlatformIdResult} message PlatformIdResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlatformIdResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ack != null && Object.hasOwnProperty.call(message, "ack"))
                $root.thimphuProtocol.AckResult.encode(message.ack, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.version);
            if (message.serialNumber != null && Object.hasOwnProperty.call(message, "serialNumber"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.serialNumber);
            return writer;
        };

        /**
         * Encodes the specified PlatformIdResult message, length delimited. Does not implicitly {@link thimphuProtocol.PlatformIdResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.PlatformIdResult
         * @static
         * @param {thimphuProtocol.IPlatformIdResult} message PlatformIdResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlatformIdResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlatformIdResult message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.PlatformIdResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.PlatformIdResult} PlatformIdResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlatformIdResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.PlatformIdResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ack = $root.thimphuProtocol.AckResult.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.id = reader.string();
                        break;
                    }
                case 3: {
                        message.version = reader.string();
                        break;
                    }
                case 4: {
                        message.serialNumber = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlatformIdResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.PlatformIdResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.PlatformIdResult} PlatformIdResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlatformIdResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlatformIdResult message.
         * @function verify
         * @memberof thimphuProtocol.PlatformIdResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlatformIdResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ack != null && message.hasOwnProperty("ack")) {
                let error = $root.thimphuProtocol.AckResult.verify(message.ack);
                if (error)
                    return "ack." + error;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            if (message.serialNumber != null && message.hasOwnProperty("serialNumber"))
                if (!$util.isString(message.serialNumber))
                    return "serialNumber: string expected";
            return null;
        };

        /**
         * Creates a PlatformIdResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.PlatformIdResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.PlatformIdResult} PlatformIdResult
         */
        PlatformIdResult.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.PlatformIdResult)
                return object;
            let message = new $root.thimphuProtocol.PlatformIdResult();
            if (object.ack != null) {
                if (typeof object.ack !== "object")
                    throw TypeError(".thimphuProtocol.PlatformIdResult.ack: object expected");
                message.ack = $root.thimphuProtocol.AckResult.fromObject(object.ack);
            }
            if (object.id != null)
                message.id = String(object.id);
            if (object.version != null)
                message.version = String(object.version);
            if (object.serialNumber != null)
                message.serialNumber = String(object.serialNumber);
            return message;
        };

        /**
         * Creates a plain object from a PlatformIdResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.PlatformIdResult
         * @static
         * @param {thimphuProtocol.PlatformIdResult} message PlatformIdResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlatformIdResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ack = null;
                object.id = "";
                object.version = "";
                object.serialNumber = "";
            }
            if (message.ack != null && message.hasOwnProperty("ack"))
                object.ack = $root.thimphuProtocol.AckResult.toObject(message.ack, options);
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.serialNumber != null && message.hasOwnProperty("serialNumber"))
                object.serialNumber = message.serialNumber;
            return object;
        };

        /**
         * Converts this PlatformIdResult to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.PlatformIdResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlatformIdResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PlatformIdResult
         * @function getTypeUrl
         * @memberof thimphuProtocol.PlatformIdResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PlatformIdResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.PlatformIdResult";
        };

        return PlatformIdResult;
    })();

    thimphuProtocol.Reserved = (function() {

        /**
         * Properties of a Reserved.
         * @memberof thimphuProtocol
         * @interface IReserved
         */

        /**
         * Constructs a new Reserved.
         * @memberof thimphuProtocol
         * @classdesc Represents a Reserved.
         * @implements IReserved
         * @constructor
         * @param {thimphuProtocol.IReserved=} [properties] Properties to set
         */
        function Reserved(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Reserved instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.Reserved
         * @static
         * @param {thimphuProtocol.IReserved=} [properties] Properties to set
         * @returns {thimphuProtocol.Reserved} Reserved instance
         */
        Reserved.create = function create(properties) {
            return new Reserved(properties);
        };

        /**
         * Encodes the specified Reserved message. Does not implicitly {@link thimphuProtocol.Reserved.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.Reserved
         * @static
         * @param {thimphuProtocol.IReserved} message Reserved message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reserved.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Reserved message, length delimited. Does not implicitly {@link thimphuProtocol.Reserved.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.Reserved
         * @static
         * @param {thimphuProtocol.IReserved} message Reserved message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reserved.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Reserved message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.Reserved
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.Reserved} Reserved
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reserved.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.Reserved();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Reserved message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.Reserved
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.Reserved} Reserved
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reserved.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Reserved message.
         * @function verify
         * @memberof thimphuProtocol.Reserved
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Reserved.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a Reserved message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.Reserved
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.Reserved} Reserved
         */
        Reserved.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.Reserved)
                return object;
            return new $root.thimphuProtocol.Reserved();
        };

        /**
         * Creates a plain object from a Reserved message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.Reserved
         * @static
         * @param {thimphuProtocol.Reserved} message Reserved
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Reserved.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Reserved to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.Reserved
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Reserved.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Reserved
         * @function getTypeUrl
         * @memberof thimphuProtocol.Reserved
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Reserved.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.Reserved";
        };

        return Reserved;
    })();

    thimphuProtocol.ReadMemResult = (function() {

        /**
         * Properties of a ReadMemResult.
         * @memberof thimphuProtocol
         * @interface IReadMemResult
         * @property {thimphuProtocol.IAckResult|null} [ack] ReadMemResult ack
         * @property {thimphuProtocol.MemId|null} [memId] ReadMemResult memId
         * @property {number|null} [offset] ReadMemResult offset
         * @property {Uint8Array|null} [data] ReadMemResult data
         */

        /**
         * Constructs a new ReadMemResult.
         * @memberof thimphuProtocol
         * @classdesc Represents a ReadMemResult.
         * @implements IReadMemResult
         * @constructor
         * @param {thimphuProtocol.IReadMemResult=} [properties] Properties to set
         */
        function ReadMemResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReadMemResult ack.
         * @member {thimphuProtocol.IAckResult|null|undefined} ack
         * @memberof thimphuProtocol.ReadMemResult
         * @instance
         */
        ReadMemResult.prototype.ack = null;

        /**
         * ReadMemResult memId.
         * @member {thimphuProtocol.MemId} memId
         * @memberof thimphuProtocol.ReadMemResult
         * @instance
         */
        ReadMemResult.prototype.memId = 0;

        /**
         * ReadMemResult offset.
         * @member {number} offset
         * @memberof thimphuProtocol.ReadMemResult
         * @instance
         */
        ReadMemResult.prototype.offset = 0;

        /**
         * ReadMemResult data.
         * @member {Uint8Array} data
         * @memberof thimphuProtocol.ReadMemResult
         * @instance
         */
        ReadMemResult.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new ReadMemResult instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.ReadMemResult
         * @static
         * @param {thimphuProtocol.IReadMemResult=} [properties] Properties to set
         * @returns {thimphuProtocol.ReadMemResult} ReadMemResult instance
         */
        ReadMemResult.create = function create(properties) {
            return new ReadMemResult(properties);
        };

        /**
         * Encodes the specified ReadMemResult message. Does not implicitly {@link thimphuProtocol.ReadMemResult.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.ReadMemResult
         * @static
         * @param {thimphuProtocol.IReadMemResult} message ReadMemResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadMemResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ack != null && Object.hasOwnProperty.call(message, "ack"))
                $root.thimphuProtocol.AckResult.encode(message.ack, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.memId != null && Object.hasOwnProperty.call(message, "memId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.memId);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.offset);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified ReadMemResult message, length delimited. Does not implicitly {@link thimphuProtocol.ReadMemResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.ReadMemResult
         * @static
         * @param {thimphuProtocol.IReadMemResult} message ReadMemResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadMemResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReadMemResult message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.ReadMemResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.ReadMemResult} ReadMemResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadMemResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.ReadMemResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ack = $root.thimphuProtocol.AckResult.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.memId = reader.int32();
                        break;
                    }
                case 3: {
                        message.offset = reader.uint32();
                        break;
                    }
                case 4: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReadMemResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.ReadMemResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.ReadMemResult} ReadMemResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadMemResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReadMemResult message.
         * @function verify
         * @memberof thimphuProtocol.ReadMemResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReadMemResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ack != null && message.hasOwnProperty("ack")) {
                let error = $root.thimphuProtocol.AckResult.verify(message.ack);
                if (error)
                    return "ack." + error;
            }
            if (message.memId != null && message.hasOwnProperty("memId"))
                switch (message.memId) {
                default:
                    return "memId: enum value expected";
                case 0:
                    break;
                }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a ReadMemResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.ReadMemResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.ReadMemResult} ReadMemResult
         */
        ReadMemResult.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.ReadMemResult)
                return object;
            let message = new $root.thimphuProtocol.ReadMemResult();
            if (object.ack != null) {
                if (typeof object.ack !== "object")
                    throw TypeError(".thimphuProtocol.ReadMemResult.ack: object expected");
                message.ack = $root.thimphuProtocol.AckResult.fromObject(object.ack);
            }
            switch (object.memId) {
            default:
                if (typeof object.memId === "number") {
                    message.memId = object.memId;
                    break;
                }
                break;
            case "EEPROM":
            case 0:
                message.memId = 0;
                break;
            }
            if (object.offset != null)
                message.offset = object.offset >>> 0;
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a ReadMemResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.ReadMemResult
         * @static
         * @param {thimphuProtocol.ReadMemResult} message ReadMemResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReadMemResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ack = null;
                object.memId = options.enums === String ? "EEPROM" : 0;
                object.offset = 0;
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.ack != null && message.hasOwnProperty("ack"))
                object.ack = $root.thimphuProtocol.AckResult.toObject(message.ack, options);
            if (message.memId != null && message.hasOwnProperty("memId"))
                object.memId = options.enums === String ? $root.thimphuProtocol.MemId[message.memId] === undefined ? message.memId : $root.thimphuProtocol.MemId[message.memId] : message.memId;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this ReadMemResult to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.ReadMemResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReadMemResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReadMemResult
         * @function getTypeUrl
         * @memberof thimphuProtocol.ReadMemResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReadMemResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.ReadMemResult";
        };

        return ReadMemResult;
    })();

    thimphuProtocol.MemInfosResult = (function() {

        /**
         * Properties of a MemInfosResult.
         * @memberof thimphuProtocol
         * @interface IMemInfosResult
         * @property {thimphuProtocol.IAckResult|null} [ack] MemInfosResult ack
         * @property {thimphuProtocol.MemId|null} [memId] MemInfosResult memId
         * @property {number|null} [size] MemInfosResult size
         * @property {number|null} [rdBufferSize] MemInfosResult rdBufferSize
         * @property {number|null} [wrBufferSize] MemInfosResult wrBufferSize
         */

        /**
         * Constructs a new MemInfosResult.
         * @memberof thimphuProtocol
         * @classdesc Represents a MemInfosResult.
         * @implements IMemInfosResult
         * @constructor
         * @param {thimphuProtocol.IMemInfosResult=} [properties] Properties to set
         */
        function MemInfosResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemInfosResult ack.
         * @member {thimphuProtocol.IAckResult|null|undefined} ack
         * @memberof thimphuProtocol.MemInfosResult
         * @instance
         */
        MemInfosResult.prototype.ack = null;

        /**
         * MemInfosResult memId.
         * @member {thimphuProtocol.MemId} memId
         * @memberof thimphuProtocol.MemInfosResult
         * @instance
         */
        MemInfosResult.prototype.memId = 0;

        /**
         * MemInfosResult size.
         * @member {number} size
         * @memberof thimphuProtocol.MemInfosResult
         * @instance
         */
        MemInfosResult.prototype.size = 0;

        /**
         * MemInfosResult rdBufferSize.
         * @member {number} rdBufferSize
         * @memberof thimphuProtocol.MemInfosResult
         * @instance
         */
        MemInfosResult.prototype.rdBufferSize = 0;

        /**
         * MemInfosResult wrBufferSize.
         * @member {number} wrBufferSize
         * @memberof thimphuProtocol.MemInfosResult
         * @instance
         */
        MemInfosResult.prototype.wrBufferSize = 0;

        /**
         * Creates a new MemInfosResult instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.MemInfosResult
         * @static
         * @param {thimphuProtocol.IMemInfosResult=} [properties] Properties to set
         * @returns {thimphuProtocol.MemInfosResult} MemInfosResult instance
         */
        MemInfosResult.create = function create(properties) {
            return new MemInfosResult(properties);
        };

        /**
         * Encodes the specified MemInfosResult message. Does not implicitly {@link thimphuProtocol.MemInfosResult.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.MemInfosResult
         * @static
         * @param {thimphuProtocol.IMemInfosResult} message MemInfosResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemInfosResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ack != null && Object.hasOwnProperty.call(message, "ack"))
                $root.thimphuProtocol.AckResult.encode(message.ack, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.memId != null && Object.hasOwnProperty.call(message, "memId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.memId);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.size);
            if (message.rdBufferSize != null && Object.hasOwnProperty.call(message, "rdBufferSize"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.rdBufferSize);
            if (message.wrBufferSize != null && Object.hasOwnProperty.call(message, "wrBufferSize"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.wrBufferSize);
            return writer;
        };

        /**
         * Encodes the specified MemInfosResult message, length delimited. Does not implicitly {@link thimphuProtocol.MemInfosResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.MemInfosResult
         * @static
         * @param {thimphuProtocol.IMemInfosResult} message MemInfosResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemInfosResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemInfosResult message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.MemInfosResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.MemInfosResult} MemInfosResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemInfosResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.MemInfosResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ack = $root.thimphuProtocol.AckResult.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.memId = reader.int32();
                        break;
                    }
                case 3: {
                        message.size = reader.uint32();
                        break;
                    }
                case 4: {
                        message.rdBufferSize = reader.uint32();
                        break;
                    }
                case 5: {
                        message.wrBufferSize = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemInfosResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.MemInfosResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.MemInfosResult} MemInfosResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemInfosResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemInfosResult message.
         * @function verify
         * @memberof thimphuProtocol.MemInfosResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemInfosResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ack != null && message.hasOwnProperty("ack")) {
                let error = $root.thimphuProtocol.AckResult.verify(message.ack);
                if (error)
                    return "ack." + error;
            }
            if (message.memId != null && message.hasOwnProperty("memId"))
                switch (message.memId) {
                default:
                    return "memId: enum value expected";
                case 0:
                    break;
                }
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size))
                    return "size: integer expected";
            if (message.rdBufferSize != null && message.hasOwnProperty("rdBufferSize"))
                if (!$util.isInteger(message.rdBufferSize))
                    return "rdBufferSize: integer expected";
            if (message.wrBufferSize != null && message.hasOwnProperty("wrBufferSize"))
                if (!$util.isInteger(message.wrBufferSize))
                    return "wrBufferSize: integer expected";
            return null;
        };

        /**
         * Creates a MemInfosResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.MemInfosResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.MemInfosResult} MemInfosResult
         */
        MemInfosResult.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.MemInfosResult)
                return object;
            let message = new $root.thimphuProtocol.MemInfosResult();
            if (object.ack != null) {
                if (typeof object.ack !== "object")
                    throw TypeError(".thimphuProtocol.MemInfosResult.ack: object expected");
                message.ack = $root.thimphuProtocol.AckResult.fromObject(object.ack);
            }
            switch (object.memId) {
            default:
                if (typeof object.memId === "number") {
                    message.memId = object.memId;
                    break;
                }
                break;
            case "EEPROM":
            case 0:
                message.memId = 0;
                break;
            }
            if (object.size != null)
                message.size = object.size >>> 0;
            if (object.rdBufferSize != null)
                message.rdBufferSize = object.rdBufferSize >>> 0;
            if (object.wrBufferSize != null)
                message.wrBufferSize = object.wrBufferSize >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a MemInfosResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.MemInfosResult
         * @static
         * @param {thimphuProtocol.MemInfosResult} message MemInfosResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemInfosResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ack = null;
                object.memId = options.enums === String ? "EEPROM" : 0;
                object.size = 0;
                object.rdBufferSize = 0;
                object.wrBufferSize = 0;
            }
            if (message.ack != null && message.hasOwnProperty("ack"))
                object.ack = $root.thimphuProtocol.AckResult.toObject(message.ack, options);
            if (message.memId != null && message.hasOwnProperty("memId"))
                object.memId = options.enums === String ? $root.thimphuProtocol.MemId[message.memId] === undefined ? message.memId : $root.thimphuProtocol.MemId[message.memId] : message.memId;
            if (message.size != null && message.hasOwnProperty("size"))
                object.size = message.size;
            if (message.rdBufferSize != null && message.hasOwnProperty("rdBufferSize"))
                object.rdBufferSize = message.rdBufferSize;
            if (message.wrBufferSize != null && message.hasOwnProperty("wrBufferSize"))
                object.wrBufferSize = message.wrBufferSize;
            return object;
        };

        /**
         * Converts this MemInfosResult to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.MemInfosResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemInfosResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MemInfosResult
         * @function getTypeUrl
         * @memberof thimphuProtocol.MemInfosResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MemInfosResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.MemInfosResult";
        };

        return MemInfosResult;
    })();

    thimphuProtocol.HostMsg = (function() {

        /**
         * Properties of a HostMsg.
         * @memberof thimphuProtocol
         * @interface IHostMsg
         * @property {number|null} [msgCnt] HostMsg msgCnt
         * @property {thimphuProtocol.IReserved|null} [reserved] HostMsg reserved
         * @property {thimphuProtocol.IStartCommand|null} [start] HostMsg start
         * @property {thimphuProtocol.IStopCommand|null} [stop] HostMsg stop
         * @property {thimphuProtocol.IConfigureCommand|null} [configure] HostMsg configure
         * @property {thimphuProtocol.IGetPlatformIdCommand|null} [getPlatformId] HostMsg getPlatformId
         * @property {thimphuProtocol.IReadMemCommand|null} [readMem] HostMsg readMem
         * @property {thimphuProtocol.IWriteMemCommand|null} [writeMem] HostMsg writeMem
         * @property {thimphuProtocol.IGetMemInfosCommand|null} [getMemInfos] HostMsg getMemInfos
         */

        /**
         * Constructs a new HostMsg.
         * @memberof thimphuProtocol
         * @classdesc Represents a HostMsg.
         * @implements IHostMsg
         * @constructor
         * @param {thimphuProtocol.IHostMsg=} [properties] Properties to set
         */
        function HostMsg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HostMsg msgCnt.
         * @member {number} msgCnt
         * @memberof thimphuProtocol.HostMsg
         * @instance
         */
        HostMsg.prototype.msgCnt = 0;

        /**
         * HostMsg reserved.
         * @member {thimphuProtocol.IReserved|null|undefined} reserved
         * @memberof thimphuProtocol.HostMsg
         * @instance
         */
        HostMsg.prototype.reserved = null;

        /**
         * HostMsg start.
         * @member {thimphuProtocol.IStartCommand|null|undefined} start
         * @memberof thimphuProtocol.HostMsg
         * @instance
         */
        HostMsg.prototype.start = null;

        /**
         * HostMsg stop.
         * @member {thimphuProtocol.IStopCommand|null|undefined} stop
         * @memberof thimphuProtocol.HostMsg
         * @instance
         */
        HostMsg.prototype.stop = null;

        /**
         * HostMsg configure.
         * @member {thimphuProtocol.IConfigureCommand|null|undefined} configure
         * @memberof thimphuProtocol.HostMsg
         * @instance
         */
        HostMsg.prototype.configure = null;

        /**
         * HostMsg getPlatformId.
         * @member {thimphuProtocol.IGetPlatformIdCommand|null|undefined} getPlatformId
         * @memberof thimphuProtocol.HostMsg
         * @instance
         */
        HostMsg.prototype.getPlatformId = null;

        /**
         * HostMsg readMem.
         * @member {thimphuProtocol.IReadMemCommand|null|undefined} readMem
         * @memberof thimphuProtocol.HostMsg
         * @instance
         */
        HostMsg.prototype.readMem = null;

        /**
         * HostMsg writeMem.
         * @member {thimphuProtocol.IWriteMemCommand|null|undefined} writeMem
         * @memberof thimphuProtocol.HostMsg
         * @instance
         */
        HostMsg.prototype.writeMem = null;

        /**
         * HostMsg getMemInfos.
         * @member {thimphuProtocol.IGetMemInfosCommand|null|undefined} getMemInfos
         * @memberof thimphuProtocol.HostMsg
         * @instance
         */
        HostMsg.prototype.getMemInfos = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * HostMsg msg.
         * @member {"reserved"|"start"|"stop"|"configure"|"getPlatformId"|"readMem"|"writeMem"|"getMemInfos"|undefined} msg
         * @memberof thimphuProtocol.HostMsg
         * @instance
         */
        Object.defineProperty(HostMsg.prototype, "msg", {
            get: $util.oneOfGetter($oneOfFields = ["reserved", "start", "stop", "configure", "getPlatformId", "readMem", "writeMem", "getMemInfos"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new HostMsg instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.HostMsg
         * @static
         * @param {thimphuProtocol.IHostMsg=} [properties] Properties to set
         * @returns {thimphuProtocol.HostMsg} HostMsg instance
         */
        HostMsg.create = function create(properties) {
            return new HostMsg(properties);
        };

        /**
         * Encodes the specified HostMsg message. Does not implicitly {@link thimphuProtocol.HostMsg.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.HostMsg
         * @static
         * @param {thimphuProtocol.IHostMsg} message HostMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HostMsg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msgCnt != null && Object.hasOwnProperty.call(message, "msgCnt"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.msgCnt);
            if (message.reserved != null && Object.hasOwnProperty.call(message, "reserved"))
                $root.thimphuProtocol.Reserved.encode(message.reserved, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.thimphuProtocol.StartCommand.encode(message.start, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.stop != null && Object.hasOwnProperty.call(message, "stop"))
                $root.thimphuProtocol.StopCommand.encode(message.stop, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.configure != null && Object.hasOwnProperty.call(message, "configure"))
                $root.thimphuProtocol.ConfigureCommand.encode(message.configure, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.getPlatformId != null && Object.hasOwnProperty.call(message, "getPlatformId"))
                $root.thimphuProtocol.GetPlatformIdCommand.encode(message.getPlatformId, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.readMem != null && Object.hasOwnProperty.call(message, "readMem"))
                $root.thimphuProtocol.ReadMemCommand.encode(message.readMem, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.writeMem != null && Object.hasOwnProperty.call(message, "writeMem"))
                $root.thimphuProtocol.WriteMemCommand.encode(message.writeMem, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.getMemInfos != null && Object.hasOwnProperty.call(message, "getMemInfos"))
                $root.thimphuProtocol.GetMemInfosCommand.encode(message.getMemInfos, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HostMsg message, length delimited. Does not implicitly {@link thimphuProtocol.HostMsg.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.HostMsg
         * @static
         * @param {thimphuProtocol.IHostMsg} message HostMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HostMsg.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HostMsg message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.HostMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.HostMsg} HostMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HostMsg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.HostMsg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msgCnt = reader.uint32();
                        break;
                    }
                case 2: {
                        message.reserved = $root.thimphuProtocol.Reserved.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.start = $root.thimphuProtocol.StartCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.stop = $root.thimphuProtocol.StopCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.configure = $root.thimphuProtocol.ConfigureCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.getPlatformId = $root.thimphuProtocol.GetPlatformIdCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.readMem = $root.thimphuProtocol.ReadMemCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.writeMem = $root.thimphuProtocol.WriteMemCommand.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.getMemInfos = $root.thimphuProtocol.GetMemInfosCommand.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HostMsg message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.HostMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.HostMsg} HostMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HostMsg.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HostMsg message.
         * @function verify
         * @memberof thimphuProtocol.HostMsg
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HostMsg.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.msgCnt != null && message.hasOwnProperty("msgCnt"))
                if (!$util.isInteger(message.msgCnt))
                    return "msgCnt: integer expected";
            if (message.reserved != null && message.hasOwnProperty("reserved")) {
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.Reserved.verify(message.reserved);
                    if (error)
                        return "reserved." + error;
                }
            }
            if (message.start != null && message.hasOwnProperty("start")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.StartCommand.verify(message.start);
                    if (error)
                        return "start." + error;
                }
            }
            if (message.stop != null && message.hasOwnProperty("stop")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.StopCommand.verify(message.stop);
                    if (error)
                        return "stop." + error;
                }
            }
            if (message.configure != null && message.hasOwnProperty("configure")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.ConfigureCommand.verify(message.configure);
                    if (error)
                        return "configure." + error;
                }
            }
            if (message.getPlatformId != null && message.hasOwnProperty("getPlatformId")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.GetPlatformIdCommand.verify(message.getPlatformId);
                    if (error)
                        return "getPlatformId." + error;
                }
            }
            if (message.readMem != null && message.hasOwnProperty("readMem")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.ReadMemCommand.verify(message.readMem);
                    if (error)
                        return "readMem." + error;
                }
            }
            if (message.writeMem != null && message.hasOwnProperty("writeMem")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.WriteMemCommand.verify(message.writeMem);
                    if (error)
                        return "writeMem." + error;
                }
            }
            if (message.getMemInfos != null && message.hasOwnProperty("getMemInfos")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.GetMemInfosCommand.verify(message.getMemInfos);
                    if (error)
                        return "getMemInfos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a HostMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.HostMsg
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.HostMsg} HostMsg
         */
        HostMsg.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.HostMsg)
                return object;
            let message = new $root.thimphuProtocol.HostMsg();
            if (object.msgCnt != null)
                message.msgCnt = object.msgCnt >>> 0;
            if (object.reserved != null) {
                if (typeof object.reserved !== "object")
                    throw TypeError(".thimphuProtocol.HostMsg.reserved: object expected");
                message.reserved = $root.thimphuProtocol.Reserved.fromObject(object.reserved);
            }
            if (object.start != null) {
                if (typeof object.start !== "object")
                    throw TypeError(".thimphuProtocol.HostMsg.start: object expected");
                message.start = $root.thimphuProtocol.StartCommand.fromObject(object.start);
            }
            if (object.stop != null) {
                if (typeof object.stop !== "object")
                    throw TypeError(".thimphuProtocol.HostMsg.stop: object expected");
                message.stop = $root.thimphuProtocol.StopCommand.fromObject(object.stop);
            }
            if (object.configure != null) {
                if (typeof object.configure !== "object")
                    throw TypeError(".thimphuProtocol.HostMsg.configure: object expected");
                message.configure = $root.thimphuProtocol.ConfigureCommand.fromObject(object.configure);
            }
            if (object.getPlatformId != null) {
                if (typeof object.getPlatformId !== "object")
                    throw TypeError(".thimphuProtocol.HostMsg.getPlatformId: object expected");
                message.getPlatformId = $root.thimphuProtocol.GetPlatformIdCommand.fromObject(object.getPlatformId);
            }
            if (object.readMem != null) {
                if (typeof object.readMem !== "object")
                    throw TypeError(".thimphuProtocol.HostMsg.readMem: object expected");
                message.readMem = $root.thimphuProtocol.ReadMemCommand.fromObject(object.readMem);
            }
            if (object.writeMem != null) {
                if (typeof object.writeMem !== "object")
                    throw TypeError(".thimphuProtocol.HostMsg.writeMem: object expected");
                message.writeMem = $root.thimphuProtocol.WriteMemCommand.fromObject(object.writeMem);
            }
            if (object.getMemInfos != null) {
                if (typeof object.getMemInfos !== "object")
                    throw TypeError(".thimphuProtocol.HostMsg.getMemInfos: object expected");
                message.getMemInfos = $root.thimphuProtocol.GetMemInfosCommand.fromObject(object.getMemInfos);
            }
            return message;
        };

        /**
         * Creates a plain object from a HostMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.HostMsg
         * @static
         * @param {thimphuProtocol.HostMsg} message HostMsg
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HostMsg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.msgCnt = 0;
            if (message.msgCnt != null && message.hasOwnProperty("msgCnt"))
                object.msgCnt = message.msgCnt;
            if (message.reserved != null && message.hasOwnProperty("reserved")) {
                object.reserved = $root.thimphuProtocol.Reserved.toObject(message.reserved, options);
                if (options.oneofs)
                    object.msg = "reserved";
            }
            if (message.start != null && message.hasOwnProperty("start")) {
                object.start = $root.thimphuProtocol.StartCommand.toObject(message.start, options);
                if (options.oneofs)
                    object.msg = "start";
            }
            if (message.stop != null && message.hasOwnProperty("stop")) {
                object.stop = $root.thimphuProtocol.StopCommand.toObject(message.stop, options);
                if (options.oneofs)
                    object.msg = "stop";
            }
            if (message.configure != null && message.hasOwnProperty("configure")) {
                object.configure = $root.thimphuProtocol.ConfigureCommand.toObject(message.configure, options);
                if (options.oneofs)
                    object.msg = "configure";
            }
            if (message.getPlatformId != null && message.hasOwnProperty("getPlatformId")) {
                object.getPlatformId = $root.thimphuProtocol.GetPlatformIdCommand.toObject(message.getPlatformId, options);
                if (options.oneofs)
                    object.msg = "getPlatformId";
            }
            if (message.readMem != null && message.hasOwnProperty("readMem")) {
                object.readMem = $root.thimphuProtocol.ReadMemCommand.toObject(message.readMem, options);
                if (options.oneofs)
                    object.msg = "readMem";
            }
            if (message.writeMem != null && message.hasOwnProperty("writeMem")) {
                object.writeMem = $root.thimphuProtocol.WriteMemCommand.toObject(message.writeMem, options);
                if (options.oneofs)
                    object.msg = "writeMem";
            }
            if (message.getMemInfos != null && message.hasOwnProperty("getMemInfos")) {
                object.getMemInfos = $root.thimphuProtocol.GetMemInfosCommand.toObject(message.getMemInfos, options);
                if (options.oneofs)
                    object.msg = "getMemInfos";
            }
            return object;
        };

        /**
         * Converts this HostMsg to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.HostMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HostMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HostMsg
         * @function getTypeUrl
         * @memberof thimphuProtocol.HostMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HostMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.HostMsg";
        };

        return HostMsg;
    })();

    thimphuProtocol.PlatformMsg = (function() {

        /**
         * Properties of a PlatformMsg.
         * @memberof thimphuProtocol
         * @interface IPlatformMsg
         * @property {number|null} [msgCnt] PlatformMsg msgCnt
         * @property {thimphuProtocol.IDataEvent|null} [data] PlatformMsg data
         * @property {thimphuProtocol.IStatusEvent|null} [status] PlatformMsg status
         * @property {thimphuProtocol.IAckResult|null} [ack] PlatformMsg ack
         * @property {thimphuProtocol.IReserved|null} [reserved] PlatformMsg reserved
         * @property {thimphuProtocol.IPlatformIdResult|null} [platformId] PlatformMsg platformId
         * @property {thimphuProtocol.IReadMemResult|null} [readMem] PlatformMsg readMem
         * @property {thimphuProtocol.IMemInfosResult|null} [memInfos] PlatformMsg memInfos
         */

        /**
         * Constructs a new PlatformMsg.
         * @memberof thimphuProtocol
         * @classdesc Represents a PlatformMsg.
         * @implements IPlatformMsg
         * @constructor
         * @param {thimphuProtocol.IPlatformMsg=} [properties] Properties to set
         */
        function PlatformMsg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlatformMsg msgCnt.
         * @member {number} msgCnt
         * @memberof thimphuProtocol.PlatformMsg
         * @instance
         */
        PlatformMsg.prototype.msgCnt = 0;

        /**
         * PlatformMsg data.
         * @member {thimphuProtocol.IDataEvent|null|undefined} data
         * @memberof thimphuProtocol.PlatformMsg
         * @instance
         */
        PlatformMsg.prototype.data = null;

        /**
         * PlatformMsg status.
         * @member {thimphuProtocol.IStatusEvent|null|undefined} status
         * @memberof thimphuProtocol.PlatformMsg
         * @instance
         */
        PlatformMsg.prototype.status = null;

        /**
         * PlatformMsg ack.
         * @member {thimphuProtocol.IAckResult|null|undefined} ack
         * @memberof thimphuProtocol.PlatformMsg
         * @instance
         */
        PlatformMsg.prototype.ack = null;

        /**
         * PlatformMsg reserved.
         * @member {thimphuProtocol.IReserved|null|undefined} reserved
         * @memberof thimphuProtocol.PlatformMsg
         * @instance
         */
        PlatformMsg.prototype.reserved = null;

        /**
         * PlatformMsg platformId.
         * @member {thimphuProtocol.IPlatformIdResult|null|undefined} platformId
         * @memberof thimphuProtocol.PlatformMsg
         * @instance
         */
        PlatformMsg.prototype.platformId = null;

        /**
         * PlatformMsg readMem.
         * @member {thimphuProtocol.IReadMemResult|null|undefined} readMem
         * @memberof thimphuProtocol.PlatformMsg
         * @instance
         */
        PlatformMsg.prototype.readMem = null;

        /**
         * PlatformMsg memInfos.
         * @member {thimphuProtocol.IMemInfosResult|null|undefined} memInfos
         * @memberof thimphuProtocol.PlatformMsg
         * @instance
         */
        PlatformMsg.prototype.memInfos = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * PlatformMsg msg.
         * @member {"data"|"status"|"ack"|"reserved"|"platformId"|"readMem"|"memInfos"|undefined} msg
         * @memberof thimphuProtocol.PlatformMsg
         * @instance
         */
        Object.defineProperty(PlatformMsg.prototype, "msg", {
            get: $util.oneOfGetter($oneOfFields = ["data", "status", "ack", "reserved", "platformId", "readMem", "memInfos"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PlatformMsg instance using the specified properties.
         * @function create
         * @memberof thimphuProtocol.PlatformMsg
         * @static
         * @param {thimphuProtocol.IPlatformMsg=} [properties] Properties to set
         * @returns {thimphuProtocol.PlatformMsg} PlatformMsg instance
         */
        PlatformMsg.create = function create(properties) {
            return new PlatformMsg(properties);
        };

        /**
         * Encodes the specified PlatformMsg message. Does not implicitly {@link thimphuProtocol.PlatformMsg.verify|verify} messages.
         * @function encode
         * @memberof thimphuProtocol.PlatformMsg
         * @static
         * @param {thimphuProtocol.IPlatformMsg} message PlatformMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlatformMsg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msgCnt != null && Object.hasOwnProperty.call(message, "msgCnt"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.msgCnt);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.thimphuProtocol.DataEvent.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                $root.thimphuProtocol.StatusEvent.encode(message.status, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.ack != null && Object.hasOwnProperty.call(message, "ack"))
                $root.thimphuProtocol.AckResult.encode(message.ack, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.reserved != null && Object.hasOwnProperty.call(message, "reserved"))
                $root.thimphuProtocol.Reserved.encode(message.reserved, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.platformId != null && Object.hasOwnProperty.call(message, "platformId"))
                $root.thimphuProtocol.PlatformIdResult.encode(message.platformId, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.readMem != null && Object.hasOwnProperty.call(message, "readMem"))
                $root.thimphuProtocol.ReadMemResult.encode(message.readMem, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.memInfos != null && Object.hasOwnProperty.call(message, "memInfos"))
                $root.thimphuProtocol.MemInfosResult.encode(message.memInfos, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PlatformMsg message, length delimited. Does not implicitly {@link thimphuProtocol.PlatformMsg.verify|verify} messages.
         * @function encodeDelimited
         * @memberof thimphuProtocol.PlatformMsg
         * @static
         * @param {thimphuProtocol.IPlatformMsg} message PlatformMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlatformMsg.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlatformMsg message from the specified reader or buffer.
         * @function decode
         * @memberof thimphuProtocol.PlatformMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {thimphuProtocol.PlatformMsg} PlatformMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlatformMsg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.thimphuProtocol.PlatformMsg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msgCnt = reader.uint32();
                        break;
                    }
                case 2: {
                        message.data = $root.thimphuProtocol.DataEvent.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.status = $root.thimphuProtocol.StatusEvent.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.ack = $root.thimphuProtocol.AckResult.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.reserved = $root.thimphuProtocol.Reserved.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.platformId = $root.thimphuProtocol.PlatformIdResult.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.readMem = $root.thimphuProtocol.ReadMemResult.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.memInfos = $root.thimphuProtocol.MemInfosResult.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlatformMsg message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof thimphuProtocol.PlatformMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {thimphuProtocol.PlatformMsg} PlatformMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlatformMsg.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlatformMsg message.
         * @function verify
         * @memberof thimphuProtocol.PlatformMsg
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlatformMsg.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.msgCnt != null && message.hasOwnProperty("msgCnt"))
                if (!$util.isInteger(message.msgCnt))
                    return "msgCnt: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.DataEvent.verify(message.data);
                    if (error)
                        return "data." + error;
                }
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.StatusEvent.verify(message.status);
                    if (error)
                        return "status." + error;
                }
            }
            if (message.ack != null && message.hasOwnProperty("ack")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.AckResult.verify(message.ack);
                    if (error)
                        return "ack." + error;
                }
            }
            if (message.reserved != null && message.hasOwnProperty("reserved")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.Reserved.verify(message.reserved);
                    if (error)
                        return "reserved." + error;
                }
            }
            if (message.platformId != null && message.hasOwnProperty("platformId")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.PlatformIdResult.verify(message.platformId);
                    if (error)
                        return "platformId." + error;
                }
            }
            if (message.readMem != null && message.hasOwnProperty("readMem")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.ReadMemResult.verify(message.readMem);
                    if (error)
                        return "readMem." + error;
                }
            }
            if (message.memInfos != null && message.hasOwnProperty("memInfos")) {
                if (properties.msg === 1)
                    return "msg: multiple values";
                properties.msg = 1;
                {
                    let error = $root.thimphuProtocol.MemInfosResult.verify(message.memInfos);
                    if (error)
                        return "memInfos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PlatformMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof thimphuProtocol.PlatformMsg
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {thimphuProtocol.PlatformMsg} PlatformMsg
         */
        PlatformMsg.fromObject = function fromObject(object) {
            if (object instanceof $root.thimphuProtocol.PlatformMsg)
                return object;
            let message = new $root.thimphuProtocol.PlatformMsg();
            if (object.msgCnt != null)
                message.msgCnt = object.msgCnt >>> 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".thimphuProtocol.PlatformMsg.data: object expected");
                message.data = $root.thimphuProtocol.DataEvent.fromObject(object.data);
            }
            if (object.status != null) {
                if (typeof object.status !== "object")
                    throw TypeError(".thimphuProtocol.PlatformMsg.status: object expected");
                message.status = $root.thimphuProtocol.StatusEvent.fromObject(object.status);
            }
            if (object.ack != null) {
                if (typeof object.ack !== "object")
                    throw TypeError(".thimphuProtocol.PlatformMsg.ack: object expected");
                message.ack = $root.thimphuProtocol.AckResult.fromObject(object.ack);
            }
            if (object.reserved != null) {
                if (typeof object.reserved !== "object")
                    throw TypeError(".thimphuProtocol.PlatformMsg.reserved: object expected");
                message.reserved = $root.thimphuProtocol.Reserved.fromObject(object.reserved);
            }
            if (object.platformId != null) {
                if (typeof object.platformId !== "object")
                    throw TypeError(".thimphuProtocol.PlatformMsg.platformId: object expected");
                message.platformId = $root.thimphuProtocol.PlatformIdResult.fromObject(object.platformId);
            }
            if (object.readMem != null) {
                if (typeof object.readMem !== "object")
                    throw TypeError(".thimphuProtocol.PlatformMsg.readMem: object expected");
                message.readMem = $root.thimphuProtocol.ReadMemResult.fromObject(object.readMem);
            }
            if (object.memInfos != null) {
                if (typeof object.memInfos !== "object")
                    throw TypeError(".thimphuProtocol.PlatformMsg.memInfos: object expected");
                message.memInfos = $root.thimphuProtocol.MemInfosResult.fromObject(object.memInfos);
            }
            return message;
        };

        /**
         * Creates a plain object from a PlatformMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof thimphuProtocol.PlatformMsg
         * @static
         * @param {thimphuProtocol.PlatformMsg} message PlatformMsg
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlatformMsg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.msgCnt = 0;
            if (message.msgCnt != null && message.hasOwnProperty("msgCnt"))
                object.msgCnt = message.msgCnt;
            if (message.data != null && message.hasOwnProperty("data")) {
                object.data = $root.thimphuProtocol.DataEvent.toObject(message.data, options);
                if (options.oneofs)
                    object.msg = "data";
            }
            if (message.status != null && message.hasOwnProperty("status")) {
                object.status = $root.thimphuProtocol.StatusEvent.toObject(message.status, options);
                if (options.oneofs)
                    object.msg = "status";
            }
            if (message.ack != null && message.hasOwnProperty("ack")) {
                object.ack = $root.thimphuProtocol.AckResult.toObject(message.ack, options);
                if (options.oneofs)
                    object.msg = "ack";
            }
            if (message.reserved != null && message.hasOwnProperty("reserved")) {
                object.reserved = $root.thimphuProtocol.Reserved.toObject(message.reserved, options);
                if (options.oneofs)
                    object.msg = "reserved";
            }
            if (message.platformId != null && message.hasOwnProperty("platformId")) {
                object.platformId = $root.thimphuProtocol.PlatformIdResult.toObject(message.platformId, options);
                if (options.oneofs)
                    object.msg = "platformId";
            }
            if (message.readMem != null && message.hasOwnProperty("readMem")) {
                object.readMem = $root.thimphuProtocol.ReadMemResult.toObject(message.readMem, options);
                if (options.oneofs)
                    object.msg = "readMem";
            }
            if (message.memInfos != null && message.hasOwnProperty("memInfos")) {
                object.memInfos = $root.thimphuProtocol.MemInfosResult.toObject(message.memInfos, options);
                if (options.oneofs)
                    object.msg = "memInfos";
            }
            return object;
        };

        /**
         * Converts this PlatformMsg to JSON.
         * @function toJSON
         * @memberof thimphuProtocol.PlatformMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlatformMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PlatformMsg
         * @function getTypeUrl
         * @memberof thimphuProtocol.PlatformMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PlatformMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/thimphuProtocol.PlatformMsg";
        };

        return PlatformMsg;
    })();

    return thimphuProtocol;
})();

export { $root as default };
