import { EventHandler, FC, useEffect, useRef, useState } from "react"
import { RecordValueWithSensograms } from "../idb/idb"
import { ARYBALLE_COLOR_CYAN, ARYBALLE_COLOR_GRAY, DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, NON_STANDARD_PEPTIDE_COLOR_MAP, PEPTIDE_COLOR_MAP_VDW, colorHexToRGBA } from "../utils"
import Plot from "react-plotly.js"
import { transpose } from "../analysis/utils"
import { DEFAULT_PLOTLY_CONFIG, DEFAULT_PLOTLY_LAYOUT, DEFAULT_PLOTLY_MARGIN } from "../constants"
import { aggregateSensogramSpans } from "../analysis/compute"

export const SingleSensogramFigure: FC<{
    record: RecordValueWithSensograms
    shouldAggregate: boolean
    setBoundaries: (
        startIdx?: number,
        endIdx?: number,
    ) => void
}> = ({
    record,
    shouldAggregate,
    setBoundaries
}) => {

    const [ plotlyData, setPlotlyData ] = useState<Plotly.Data[]>([])
    const [ plotlyLayout, setPlotlyLayout ] = useState<Partial<Plotly.Layout>>({})
    const [ plotlyConfig, setPlotlyConfig ] = useState<Partial<Plotly.Config>>({})

    useEffect(() => { 

        let _spotsgrid1d = record.device.spotsgrid
        let _sensogramSpans = transpose(record.sensogramSeries)

        let excludedSensogramSpans: number[][] = []
        let excludedSpotsgrid1d: number[] = []
        for (let i = 0; i < _spotsgrid1d.length; i++) {
            let sensorInt = _spotsgrid1d[i]
            if (sensorInt >= 1) {
                excludedSensogramSpans.push([..._sensogramSpans[i]])
                excludedSpotsgrid1d.push(sensorInt)
            }
        }

        let finalSensogramSpans: number[][] = []
        let finalSpotsgrid1d: number[] = []

        if (shouldAggregate)   {
            // aggregate by common spot name
            let [ aggregatedSensogramSpans, aggregatedSpotsgrid1d ] = aggregateSensogramSpans(
                excludedSensogramSpans,
                excludedSpotsgrid1d
            )
            finalSensogramSpans = aggregatedSensogramSpans
            finalSpotsgrid1d = aggregatedSpotsgrid1d
        } else {
            finalSensogramSpans = [...excludedSensogramSpans]
            finalSpotsgrid1d = [...excludedSpotsgrid1d]
        }

        var apexMax = 0
        finalSensogramSpans.forEach((span) => {
            apexMax = Math.max(apexMax, Math.max(...span))
        })
        
        var apexMin = 0
        finalSensogramSpans.forEach((span) => {
            apexMin = Math.min(apexMin, Math.min(...span))
        })
        
        let blColor = ARYBALLE_COLOR_GRAY
        let blColorLine = blColor
        let blColorFill = colorHexToRGBA(blColor, .4)
        
        let anColor = ARYBALLE_COLOR_CYAN
        let anColorLine = anColor
        let anColorFill = colorHexToRGBA(anColor, .4)
        
        var plotlyData: Plotly.Data[] = []
        
        
        if (record.baselineStart !== undefined && record.baselineEnd !== undefined) {
            let baselineLeft = record.baselineStart
            let baselineRight = (record.baselineEnd - 1)
            let baselineLeftX = new Date(record.sensogramTimestamps[baselineLeft])
            let baselineRightX = new Date(record.sensogramTimestamps[baselineRight])
            plotlyData.push({
                type: 'scatter',
                x: [baselineLeftX, baselineLeftX],
                y: [apexMin, apexMax],
                name: 'baseline_start',
                legendgroup: '_',
                showlegend: false,
                line: {
                    color: blColorLine,
                },
            })
            plotlyData.push({
                type: 'scatter',
                x: [baselineRightX, baselineRightX],
                y: [apexMin, apexMax],
                name: 'baseline_end',
                legendgroup: '_',
                showlegend: false,
                fill: 'tonextx',
                line: {
                    color: blColorLine,
                },
                fillcolor: blColorFill,
            })
        }
    
        if (record.analyteStart !== undefined && record.analyteEnd !== undefined) {
            let analyteLeft = record.analyteStart
            let analyteRight = (record.analyteEnd - 1)
            let analyteLeftX = new Date(record.sensogramTimestamps[analyteLeft])
            let analyteRightX = new Date(record.sensogramTimestamps[analyteRight])
            plotlyData.push({
                type: 'scatter',
                x: [analyteLeftX, analyteLeftX],
                y: [apexMin, apexMax],
                name: 'analyte_start',
                legendgroup: '_',
                showlegend: false,
                line: {
                    color: anColorLine,
                },
            })
            plotlyData.push({
                type: 'scatter',
                x: [analyteRightX, analyteRightX],
                y: [apexMin, apexMax],
                name: 'analyte_end',
                legendgroup: '_',
                showlegend: false,
                fill: 'tonextx',
                line: {
                    color: anColorLine,
                },
                fillcolor: anColorFill,
            })
        }
        
        
        const X = record.sensogramTimestamps //.map(ts => (ts-record.sensogramTimestamps[0])/1000)
        finalSensogramSpans.forEach((span, i) => {
            let spotInt = finalSpotsgrid1d[i]
            if (spotInt === undefined) {
                spotInt = 0
            }
            let color: string = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE
            if (PEPTIDE_COLOR_MAP_VDW[spotInt] !== undefined) {
                color = PEPTIDE_COLOR_MAP_VDW[spotInt]
            } else {
                color = NON_STANDARD_PEPTIDE_COLOR_MAP[spotInt % NON_STANDARD_PEPTIDE_COLOR_MAP.length]
            }
            // console.log('record sensogram figure: spotInt', spotInt, 'color', color)
            
            plotlyData.push({
                type: 'scatter',
                x: X.map(ts => new Date(ts)),
                y: span.map((e) => e.toFixed(3)),
                name: spotInt.toString(),
                legendgroup: '&nbsp' + spotInt,
                line: {
                    color: color,
                },
            })
        })    
        
        let plotlyConfig = {...DEFAULT_PLOTLY_CONFIG}
        var plotlyLayout = {...DEFAULT_PLOTLY_LAYOUT}

        plotlyLayout.dragmode = 'select'
        plotlyLayout.datarevision = Math.random()
        plotlyLayout.yaxis = {
            title: { text: 'Intensity, rad' },
            automargin: true,
        }
        plotlyLayout.xaxis = {
            // title: { text: 'Time, sec', position: 'bottom left' },
            // range: [0, X[X.length - 1]],
            automargin: true,
        }

        setPlotlyData(plotlyData)
        setPlotlyLayout(plotlyLayout)
        setPlotlyConfig(plotlyConfig)

    }, [
        record.baselineStart,
        record.baselineEnd,
        record.analyteStart,
        record.analyteEnd,
        shouldAggregate,
        setBoundaries,
    ])

    return <Plot
        data={plotlyData}
        layout={plotlyLayout}
        config={plotlyConfig}
        onSelected={(e) => {
            if (e.range === undefined) {
                return
            }
            let [ startTimeStr, endTimeStr ] = e.range.x as unknown as [string, string]
            let startTime = new Date(startTimeStr).getTime()
            let endTime = new Date(endTimeStr).getTime()
            console.log('single sensogram figure: onselected times', startTime, endTime)
            // find closest sensogramTimestamps indices
            let startIdx = 0
            let endIdx = 0
            for (let i = 0; i < record.sensogramTimestamps.length; i++) {
                if (record.sensogramTimestamps[i] <= startTime) {
                    startIdx = i
                }
                if (record.sensogramTimestamps[i] <= endTime) {
                    endIdx = i
                }
            }
            console.log('single sensogram figure: onselected indices:', startIdx, endIdx)
            setBoundaries(
                startIdx,
                endIdx
            )
        }}
        style={{
            width: '100%',
            height: '100%',
            margin: 'auto',
        }}
        useResizeHandler
    />
}