import { DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, PEPTIDE_COLOR_MAP_VDW, colorTupleToRGBA, colorHexToRGBA, spotsgrid2dCoordinatesTo1dIndex, rowIdxToLetter, trimSensor } from "../utils";


const SPOTFILE_TOKEN = 'glpat-xxm-gFbsq5yad24xQYNg';
const SPOTFILE_ENDPOINT = "https://gitlab.com/api/v4/projects/Aryballe%2Fproduction%2Fspotfiles/repository"

export const parseSpotsgrid2dFromSpotfile = (spotfile: string): number[][] => {
    const spotFileLines: string[] = spotfile.slice(0, spotfile.length - 1).split('\n');
    return spotFileLines.map((spotFileLine: string) => spotFileLine.split(',').map((spotCode: string) => Number(spotCode)));
}

export const fetchSpotfile = async (sdsSerial: string): Promise<string> => {
    const response = await fetch(
        `${SPOTFILE_ENDPOINT}/files/${sdsSerial}.fsp64.spotsgrid.csv/raw?access_token=${SPOTFILE_TOKEN}`
    )
    const spotfile = await response.text()
    return spotfile
}

const _fetchSpotfileListPage = async (page: number): Promise<string[]> => {
    const response = await fetch(
        `${SPOTFILE_ENDPOINT}/tree?access_token=${SPOTFILE_TOKEN}&per_page=100&page=${page}`
    )
    const spotfileList = await response.json()
    return spotfileList
        .filter((spotfile: any) => spotfile.name.endsWith('.fsp64.spotsgrid.csv'))
        .map((spotfile: any) => spotfile.name.split('.')[0])
}

export const fetchSpotfileList = async (): Promise<string[]> => {
    let page = 1
    let spotfileList: string[] = []
    while (true) {
        const _spotfileList = await _fetchSpotfileListPage(page)
        if (_spotfileList.length === 0) {
            break
        }
        spotfileList = spotfileList.concat(_spotfileList)
        page++
    }
    return spotfileList
}

export const spotsgrid2DTo1D = (spotsgrid2d: number[][]): number[] => {
    let spotsgrid1d: number[] = []
    // From left to right, from bottom to top
    for (let i = spotsgrid2d.length - 1; i >= 0; i--) {
        spotsgrid1d = spotsgrid1d.concat(spotsgrid2d[i])
    }
    return spotsgrid1d
}

export const spotsgrid1DTo2D = (spotsgrid1d: number[]): number[][] => {
    let spotsgrid2d: number[][] = []
    // From left to right, from bottom to top
    for (let i = 0; i < spotsgrid1d.length; i++) {
        const row = 15 - Math.floor(i / 4)
        const col = i % 4
        if (!spotsgrid2d[row]) {
            spotsgrid2d[row] = []
        }
        spotsgrid2d[row][col] = spotsgrid1d[i]
    }
    // console.log(spotsgrid1d, spotsgrid2d)
    return spotsgrid2d
}

export const getSpotsgridTable = (
    untrimmedSpotsgrid1d: number[],
    useColors: boolean,
    onClick: (idx: number) => void
) => {
    let spotsgrid2d = spotsgrid1DTo2D(untrimmedSpotsgrid1d)

    // For now, the spotgrid is always being returned trimmed by the FW and aggregated following that trimmed grid
    // So let's always trim the spotgrid to avoid any mismatch between what the user sees and what is really used inside the FW
    // let trimSpotsgrid = shouldTrimSpotsgrid(spotsgrid1d)
    let trimSpotsgrid = true
    return <table style={{
        borderCollapse: 'collapse',
        border: '1px solid #666',
    }}>
        <tbody>
            {
                <tr>
                    {[null, 0, 1, 2, 3].map((colTitle) => {
                        return <td key={""+colTitle} style={{
                            textAlign: 'center',
                        }}>
                            {colTitle}
                        </td>
                    })}
                </tr>
            }
            {spotsgrid2d.map((row, rowIdx) => {
                let rowCells = row.map((spotInt, colIdx) => {

                    let spotStr = spotInt.toString()

                    let peptideInt = parseInt(spotStr)
                    if (peptideInt < 0) {
                        peptideInt = -peptideInt
                    }
                    if (trimSpotsgrid) {
                        peptideInt = trimSensor(peptideInt)
                    }

                    let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE
                    if (PEPTIDE_COLOR_MAP_VDW[peptideInt] !== undefined) {
                        color = PEPTIDE_COLOR_MAP_VDW[peptideInt]
                    }
                    return <td key={colIdx} style={{
                        textAlign: 'center',
                        cursor: 'pointer',
                        border: '1px solid #666',
                        width: 60,
                        height: 25,
                        backgroundColor: function(){
                            if (spotInt < 0) {
                                return colorTupleToRGBA([255, 0, 0], 0.7)
                            }
                            if (!useColors) {
                                return 'transparent'
                            }
                            if (spotInt > 0) {
                                return colorHexToRGBA(color, 0.2)
                            }
                        }(),
                    }}
                    onClick={() => {
                        let idx = spotsgrid2dCoordinatesTo1dIndex(rowIdx, colIdx)
                        onClick(idx)
                    }}
                    >{peptideInt}</td>
                })
                let rowStr = rowIdxToLetter(rowIdx)
                return <tr key={rowIdx}>
                    <td style={{textAlign: 'center'}}>{rowStr}</td>{rowCells}
                </tr>
            })}
        </tbody>
    </table>
}

export const spotsgrids1dAreEqual = (spotsgrid1d1: number[], spotsgrid1d2: number[]): boolean => {
    if (spotsgrid1d1.length !== spotsgrid1d2.length) {
        return false
    }
    for (let i = 0; i < spotsgrid1d1.length; i++) {
        if (spotsgrid1d1[i] !== spotsgrid1d2[i]) {
            return false
        }
    }
    return true
}